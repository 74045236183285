import React, { useEffect, useState } from "react";
import { styled } from "@nextui-org/react";
import theme from "../../../../theme";
import { useFormContext, Controller } from 'react-hook-form';
import { useSelector } from "react-redux";
import { getUsers } from "../../../../services/user.services";
import useUserRoles from "../../../../hooks/useUserRoles";
//Componentes
import Icon from "../../../../components/Icon/Icon";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import RadioGroup from "../../../../components/RadioGroup/RadioGroup";
import Textarea from "../../../../components/Textarea/Textarea";
import InputSelect from "../../../../components/Select/inputSelect";
import Input from "../../../../components/Input/Input";

import { ROLES } from "../../../../constants";
import { useTranslation } from "react-i18next";
import Button from "../../../../components/Button/Button";
import RegisterModal from "./RegisterModal";


const StyledAdditional = styled("div", {
    [`.${theme} &`]: {
        margin: '3rem 8rem 0rem 8rem',
        padding: '3rem 8rem',
        boxShadow:'$elevation_1',
        backgroundColor: '$neutral_50',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        '.header':{
            display:'flex',
            justifyContent:'space-between',
            color:'$primary_300',
            fontSize:'18px',
            'div':{display:'flex', gap:'10px', alignItems:'center'},
        },
        ['aside']: {
            color: "$primary_200"
        },
        ['main']: {
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
        }
    },
    "@media (max-width: 1200px)":{
      [`.${theme} &`]:{
        margin:"1rem 2rem",
        padding:"2rem",
        fontSize:'12px',
        '.header':{
            'span':{fontSize:'12px'}
        }
      },
    },
    "@media (max-width: 630px)":{
      [`.${theme} &`]:{
        margin:"0",
        marginTop:'20px',
        padding:"1rem",
        fontSize:'12px',
        '.header':{
            flexDirection:'column',
            gap:'20px',
        }
      },
    },
});

const binaryOptions = [
    {value: true, name: "Sí"},
    {value: false,name: "No"}
]

const nameForm = 'additional'

function Additional({client, setClient, loadTypes, setDisabledSave, countries }) {
    const user = useSelector(state => state.user)

    const [users, setUsers] = useState([])
    const [open, setOpen] = useState(false)
    const userRoles = useUserRoles()
    const { t: translate } = useTranslation("Quotation");

    const havePermissions = (allowedRoles) => {
        return allowedRoles.find(role => userRoles.includes(role))
    }

    function getData(){
        getUsers(user.token).then(async res => {
            const data = await res.json();
            setUsers(data)
        }).catch( err => console.log('err :',err))
    }

    useEffect(()=>{
        console.log(client)
    },[client])
    useEffect(()=>{
        getData()
    },[])

    const { register, control, getValues, watch } = useFormContext({
        defaultValues: {
            [nameForm]: {
                packaging:binaryOptions[1].value,
                // permissions:binaryOptions[1].value,
                temperatureControl: binaryOptions[1].value,
                dataLogger: binaryOptions[1].value,
                remountable: binaryOptions[1].value,
                arancelaryPosition: binaryOptions[1].value,
                routeStudy: binaryOptions[1].value,
                securityAnalysis: binaryOptions[1].value,
                lashingSurvey: binaryOptions[1].value,
                workCommunities: binaryOptions[1].value,
                additionalSpecifications: '',
            }
        }
    });

    useEffect(() => {
        //VALIDACIONES
        const additional = getValues('additional')
        if (
            (additional.packaging && !additional.whichPackaging) ||
            (additional.permissions && !additional.whichPermissions) ||
            watch(`${nameForm}.additionalSpecifications`).length > 244
        ){
            setDisabledSave(true)
        } else {
            setDisabledSave(false)
        }
    }, [watch()]);

    const loadType = getValues('liquidation.loadType')

    return (
        <>
        <StyledAdditional>
            <div className='header'>
                <PageTitle>
                   {translate("load_information")}
                </PageTitle>
                <div>
                    <span>{countries.find(country=>country.id===getValues('send.originCountry'))?.name}</span>
                    <Icon name='SimpleArrowRight' size='30px' color='#3D509A'/>
                    <span>{countries.find(country=>country.id===getValues('send.destinationCountry'))?.name}</span>
                    <Icon 
                        size='25px'
                        name={
                            getValues('commerce.shippingMethod.value') === 1 ? "Aereo" :
                            [2,4,5,6].includes(getValues('commerce.shippingMethod.value'))? "Maritimo" :
                            "Terrestre"
                        }
                    />
                </div>
            </div>
            
            {havePermissions([ROLES.Admin, ROLES.Advisor, ROLES.Pricing]) && 
            <InputSelect
                label={translate("select_customer")}
                value={client}
                onChange={(e) => setClient(e)}
                options={users.filter(user=>user.role_id=='4').map(user => {
                    return {
                        label:`${user.email} - ${user.name} ${user.last_name}`,
                        value:user.id,
                        email:user.email,
                    }
            })}/>}
            <div style={{display:'flex', fontSize:'12px', gap:'10px'}}>
                <span>{translate("no_customer")}</span>
                <button 
                    style={{padding:'3px', color:'white', background:'#3D509A', borderRadius:'4px', cursor:'pointer'}}
                    onClick={()=>setOpen(true)}
                >
                    {translate("new_client")}
                </button>
            </div>
            
            <Controller
                control={control}
                name={`${nameForm}.purchaseOrder`}
                render={({field}) => (
                    <Input label={translate("po_number")} {...field}/>
                )}
            ></Controller>

            {
                loadType?.label === 'Carga General'?
                <aside>Necesitamos información adicional de tu carga general.</aside>:
                <aside>Necesitamos información adicional de tu carga proyecto.</aside>
            }

            <main>
                {
                    loadType?.label === 'Carga General' ?
                        <>  
                            {watch(`${nameForm}.packaging`) && <Input label={"Cual?"} placeholder={'Embalaje para mercancías peligrosas'}/>}
                            {
                                watch(`${nameForm}.packaging`) && 
                                <Controller
                                    control={control}
                                    name={`${nameForm}.whichPackaging`}
                                    render={({ field }) => (
                                        <Input label={"Cual?"} placeholder={'Embalaje para mercancías peligrosas'} {...field}/>
                                    )}
                                />
                            }

                            <Controller
                                control={control}
                                name={`${nameForm}.imo`}
                                render={({ field: { onChange, value } }) => (
                                    <RadioGroup onChange={(value) => { onChange(value); }} name={`${nameForm}.permissions`} value={value} label='1 - ¿La carga requiere algún tipo de permiso o autorización especial de IMO?' options={binaryOptions} required></RadioGroup>
                                )}
                            ></Controller>

                            {watch(`${nameForm}.imo`) && <Input label={"Cual?"} placeholder={'Permiso Especial de Navegación'}/>}
                            <Controller
                                control={control}
                                name={`${nameForm}.temperatureControl`}
                                render={({ field: { onChange, value } }) => (
                                    <RadioGroup onChange={(value) => { onChange(value); }} name={`${nameForm}.temperatureControl`} value={value} label='1 - ¿Requieres control de temperatura?' options={binaryOptions} required></RadioGroup>
                                )}
                            ></Controller>

                            <Controller
                                control={control}
                                name={`${nameForm}.dataLogger`}
                                render={({ field: { onChange, value } }) => (
                                    <RadioGroup onChange={(value) => { onChange(value); }} name={`${nameForm}.dataLogger`} value={value} label='2 - ¿Requieres de data logger?' options={binaryOptions} required></RadioGroup>
                                )}
                            ></Controller>

                               <Controller
                                control={control}
                                name={`${nameForm}.customs`}
                                render={({ field: { onChange, value } }) => (
                                    <RadioGroup onChange={(value) => { onChange(value); }} name={`${nameForm}.customs`} value={value} label='2 - ¿Requieres Aduana?' options={binaryOptions} required></RadioGroup>
                                )}
                            ></Controller>

                            <Controller
                                control={control}
                                name={`${nameForm}.remountable`}
                                render={({ field: { onChange, value } }) => (
                                    <RadioGroup onChange={(value) => { onChange(value) }} name={`${nameForm}.remountable`} value={value} label='3 - ¿La carga es remontable?' options={binaryOptions} required></RadioGroup>
                                )}
                            ></Controller>

                            <Controller
                                control={control}
                                name={`${nameForm}.arancelaryPosition`}
                                render={({ field: { onChange, value } }) => (
                                    <RadioGroup onChange={(value) => { onChange(value) }} name={`${nameForm}.arancelaryPosition`} value={value} label='4 - ¿Requieres posición arancelaria?' options={binaryOptions} required></RadioGroup>
                                )}
                            ></Controller>
                        </>
                        :
                        <>
                            <Controller
                                control={control}
                                name={`${nameForm}.routeStudy`}
                                render={({ field: { onChange, value } }) => (
                                    <RadioGroup onChange={(value) => { onChange(value) }} name={`${nameForm}.routeStudy`} value={value} label='1 - ¿Requiere estudio de ruta?' options={binaryOptions} required></RadioGroup>
                                )}
                            ></Controller>

                            <Controller
                                control={control}
                                name={`${nameForm}.securityAnalysis`}
                                render={({ field: { onChange, value } }) => (
                                    <RadioGroup onChange={(value) => { onChange(value) }} name={`${nameForm}.securityAnalysis`} value={value} label='2 - ¿Requiere análisis de seguridad?' options={binaryOptions} required></RadioGroup>
                                )}
                            ></Controller>

                            <Controller
                                control={control}
                                name={`${nameForm}.lashingSurvey`}
                                render={({ field: { onChange, value } }) => (
                                    <RadioGroup onChange={(value) => { onChange(value)}} name={`${nameForm}.lashingSurvey`} value={value} label='3 - ¿Requiere certificación de trincado o suveyor?' options={binaryOptions} required></RadioGroup>
                                )}
                            ></Controller>

                            <Controller
                                control={control}
                                name={`${nameForm}.workCommunities`}
                                render={({ field: { onChange, value } }) => (
                                    <RadioGroup onChange={(value) => { onChange(value)}} name={`${nameForm}.workCommunities`} value={value} label='4 - ¿Requiere trabajo con comunidades?' options={binaryOptions} required></RadioGroup>
                                )}
                            ></Controller>
                        </>
                }

                    <Controller
                        control={control}
                        name={`${nameForm}.additionalSpecifications`}
                        render={({ field  }) => (
                            <Textarea {...field} label='5 - Especificaciones adicionales' placeholder='Ejemplo: Contenido inflamable...'/>
                        )}
                    ></Controller>
                    {watch(`${nameForm}.additionalSpecifications`).length > 244 &&
                        <p style={{color:'red', fontSize:'12px'}}>Maximo de caracteres superado</p>
                    }
            </main>
        </StyledAdditional>
        <RegisterModal open={open} setOpen={setOpen} getData={getData}/>
        </>
    );

}
export default Additional;