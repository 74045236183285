import React, { useEffect, useState } from 'react';
import { styled } from '@nextui-org/react';
import theme from '../../../../theme';
import { useSearchParams, useNavigate } from 'react-router-dom';
import useUserRoles from '../../../../hooks/useUserRoles';
import { getQuotation, getServiceSplitRadication } from '../../../../services/quotation.services';
import { useSelector } from 'react-redux';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ViewsHandler from '../../../../components/ViewsHandler/ViewsHandler';
import { ROLES } from '../../../../constants';
// Components
import PageTitle from '../../../../components/PageTitle/PageTitle';
import Chat from './Chat/Chat';
import Participants from './Participants/Participants';
import Attachments from './Attachments/Attachments';
import ServiceDetails from './Details/ServiceDetails';
import { useTranslation } from "react-i18next";
import TrackingLogs from '../../../OperationsTracking/Events/TrackingLogs';
import { getOperations, getSubStatuses, getSubStatusesOperations } from '../../../../services/operations.services';


const StyledServiceChat = styled('div', {
    [`.${theme} &`]: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        ['header']: {
            backgroundColor: '$neutral_50',
            padding: '1rem 2rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            ['.title']: {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }
        },
        ['.content']: {
            display: 'flex',
            height: 'calc(100vh - 198px)',
            ['& > div']: {
                width: '100%'
            }
        },
        ['.css-1a4cg4j-MuiButtonBase-root-MuiTab-root']: {
            fontFamily: 'inherit',
            color: '$primary_300',
            textTransform: 'none'
        },
        ['.Mui-selected']: {
            fontWeight: '600'
        },
        ['.MuiTabs-indicator']: {
            backgroundColor: '$secondary_300'
        }
    }
})

const ServiceChat = () => {
    const userRoles = useUserRoles()
    const { t: translate } = useTranslation("Services");

    const havePermissions = (allowedRoles) => {
        return allowedRoles.find(role => userRoles.includes(role))
    }
/** Traducir */
    let tabs = [
        { value: 'chat', label: translate('chat') },
        { value: 'attachments', label: translate('attachments') },
        { value: 'participants', label: translate('participants') },
        { value: 'logs', label: translate('logs') },
        { value: 'details', label: translate('details') },
    ]
  
    const navigate = useNavigate()
    const token = useSelector(state => state.user.token)
    const [searchParams] = useSearchParams()
    const radication = searchParams.get('radication')
    const [currentOperation, setCurrentOperation] = useState()
    const [service, setService] = useState({})
    const [tabId, setTabId] = useState(tabs.findIndex(tab => tab.value === searchParams.get('tab')));
    const [tabValue, setTabValue] = useState(searchParams.get('tab'))
    const [subState, setSubState] = useState("Pending")

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
        setTabId(tabs.findIndex(tab => tab.value === newValue))
        navigate(`/services/detail/chat/?radication=${radication}&tab=${newValue}`)
    };

    useEffect(() => {
        const savedService = localStorage.getItem('service');
        if (savedService) {
            setService(JSON.parse(savedService));
        }
        console.log("radication :",radication)
        const asyncTasks = [
            getServiceSplitRadication(radication,token),
            // getQuotation(radication, token),
            getOperations({token:token}),
            getSubStatusesOperations(token),
            getSubStatuses(token),
        ]
        Promise.all(asyncTasks).then(async res =>{
            const quotationData = await res[0].json();
            const operationsData = await res[1].json();
            const relations = await res[2].json();
            const subStatuses = await res[3].json();

            setService(quotationData.results[0]);
            console.log('quotationData :',quotationData)
            localStorage.setItem('service', JSON.stringify(quotationData.results[0]));

            const currentOp = operationsData?.find(operation=>operation.radication === radication)
            setCurrentOperation(currentOp?.id)
            const currentOperationSubStates = relations.filter(relation => relation.operation === currentOp?.id)
            const sortedOperationSubStates = currentOperationSubStates?.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
            const lastSubStatus = sortedOperationSubStates[0];
            const subStatusData = subStatuses.find(sub=>sub.id === lastSubStatus?.operation_sub_status)
            setSubState(subStatusData?.name)
        })
    }, []);

    return (
        <StyledServiceChat>
            <header>
                <div className='title'>
                    <PageTitle>
                    {
                            radication.startsWith('QRFQ') ? 
                            <div>Cotizacion Rápida # {radication}</div> : 
                            <div>{translate("service")} # {radication}</div>
                            }
                    </PageTitle>
                    <p><b>{translate("status")}: </b>{subState || "Pending"}</p>
                </div>

                <Tabs
                    value={tabValue}
                    variant="scrollable"
                    onChange={handleChange}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="service"
                >
                    <Tab key={1} value='chat' label={translate('chat')} />,,
                    <Tab key={2} value='attachments' label={translate('attachments')} />,
                    <Tab key={3} value='participants' label={translate('participants')} />,
                    <Tab key={4} value='logs' label={translate('logs')} />,
                    <Tab key={5} value='details' label={translate('sustain')} disabled={radication.startsWith('QRFQ') || !havePermissions([ROLES.Admin, ROLES.Co2, ROLES.User])}/>
                </Tabs>
            </header>

            <div className='content'>
                <ViewsHandler
                    activeView={tabId}
                >
                    <Chat radication={radication} />
                    <Attachments radication={radication}></Attachments>
                    <Participants service={service} setService={setService}></Participants>
                    <TrackingLogs currentOperation={currentOperation} />
                    {havePermissions([ROLES.Admin, ROLES.Co2, ROLES.User]) && <ServiceDetails service={service} />}
                </ViewsHandler>
            </div>
        </StyledServiceChat>
    );
}

export default ServiceChat;
