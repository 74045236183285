import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Step, StepLabel, Stepper } from '@mui/material';
import { styled } from '@nextui-org/react';
import ViewsHandler from '../../../../components/ViewsHandler/ViewsHandler';
import { FormProvider, useForm } from 'react-hook-form';
import General from './General';
import Shipment from './Shipment';
import Costs from './Costs/Costs';

import Button from '../../../../components/Button/Button';
import { useSelector } from 'react-redux';
import { getServiceSplitRadication } from '../../../../services/quotation.services';
import { format } from 'date-fns';
import { getUsers } from '../../../../services/user.services';
import { getBusinessTerms } from '../../../../services/businessTerms.services';
import { getTrms } from '../../../../services/kpis.services';
import { createPreliquidation, editPreliquidation, getLoadTypes, getMoveTypes, getPreliquidations, getProviders } from '../../../../services/preliquidation.services';
import toast from '../../../../components/Toast/ToastTypes';
import { defaultValues, steps, netDefaultData, netAirDefaultData, servicesDefaultData } from './constants';
import { getCountriesNew } from '../../../../services/countries.services';
import { getShippingMethods } from '../../../../services/shippingMethods.services';
import { color } from 'echarts';
import InputFileCard from '../../../../components/InputFileCard/InputFileCard';

  const Container = styled("form",{
    background:'white',
    borderRadius:'8px',
    width:'95%',
    margin:'10px auto',
    padding:'30px',
    boxShadow:'$elevation_1'
  })

  const StyledStep = styled(Step, {
      ['.MuiStepLabel-root']: {
        position: 'relative',
        zIndex: '1',
        ['.MuiStepLabel-iconContainer']: {
          backgroundColor: '$primary_50',
          ['svg']: {
            borderRadius: '1rem',
            color: '$primary_300',
            ['circle']: {
              color: '$primary_300',
            },
            ['text']: {
              fill: '$neutral_50'
            }
          }
        },
        ['.MuiStepLabel-iconContainer.Mui-disabled']: {
          backgroundColor: '$primary_50',
          ['svg']: {
            border: 'solid 1px $primary_300',
            borderRadius: '1rem',
            ['circle']: {
              color: '$primary_50',
            },
            ['text']: {
              fill: '$primary_300'
            }
          }
        },
        ['.MuiStepLabel-label, .MuiStepIcon-text']: {
          fontFamily: 'inherit'
        },
        ['.MuiStepLabel-label.Mui-completed, .MuiStepLabel-label.Mui-active']: {
          color: '$primary_300'
        },
        ['.MuiStepLabel-label.Mui-disabled']: {
          color: '$primary_100'
        }
      },
      ['.MuiStepConnector-root']: {
        zIndex: '0',
        left: '-50%',
        right: '50%',
        ['span']: {
          border: 'solid 1.5px $primary_300',
        }
      }
  })

  function convertToMeters(value, unit) {
    const currentUnit = unit.trim()
    switch (currentUnit) {
        case 'cm':
            return value / 100;
        case 'in':
            return value * 0.0254;
        case 'm':
            return value;
        default:
            return 0;
    }
  }

  function calculateVolume(amount, long, width, height, longUnit) {
    const longInMeters = convertToMeters(long, longUnit);
    const widthInMeters = convertToMeters(width, longUnit);
    const heightInMeters = convertToMeters(height, longUnit);
    return longInMeters * widthInMeters * heightInMeters * amount;
  }

  function calculateShippingInfo(loads) {
    let totalAmount = 0;
    let totalVolume = 0;
    let totalWeight = 0;
    let totalValue = 0;
    for (let i = 0; i < loads?.length; i++) {
        const item = loads[i];
        const volume = calculateVolume(item.amount,item.long, item.width, item.height, item.long_unit);
        totalVolume += volume;
        totalAmount += item.amount;
        totalWeight += item.total_weight_unit === 'lb' ? item.total_weight * 0.453592 : item.total_weight;
        totalValue += item.value;
    }
    const totalWeightTons = totalWeight
    return {
        totalAmount,
        totalVolume,
        totalWeightTons,
        totalValue,
    };
}

export default function CreatePreliquidation() {
  const { formState: { errors, isValid }, trigger , handleSubmit, ...methods } = useForm({
    mode: 'all',
    defaultValues:defaultValues,
  });
  // console.log("errors :",errors)
  const navigate = useNavigate()
  const [rfq, setRfq] = useState({})
  const [activeStep, setActiveStep] = useState(0);
  const [users, setUsers] = useState([])
  const [quotationId, setQuotationId] = useState()
  const [searchParams] = useSearchParams()
  const currentUser = useSelector(state=>state.user)
  const token = currentUser.token
  const preliquidationId = searchParams.get('preliquidationId')
  const duplicateId = searchParams.get('duplicate')
  useEffect(()=>{
    const radication = searchParams.get('radication')
    const quoteId = searchParams.get('id')
    // const preliquidationId = searchParams.get('preliquidationId')

    const asyncTasks = [getServiceSplitRadication(radication,token), getUsers(token), getBusinessTerms(token), getTrms(token), getCountriesNew(token), getShippingMethods(token)]
    if (preliquidationId){
      asyncTasks.push(getPreliquidations(token, preliquidationId), getMoveTypes(token), getLoadTypes(token), getProviders(token))
    }
    if (duplicateId){
      asyncTasks[10]=getPreliquidations(token, duplicateId)
    }

    Promise.all(asyncTasks).then(async res => {
      const rfqDataSplit =await res[0].json();
      const usersData =await res[1].json();
      const incotermsData =await res[2].json();
      const trmData = await res[3].json();
      const countriesData = await res[4]?.json()
      const shippingMethodsData = await res[5].json();

      const preliquidationData = await res[6]?.json()
      const moveTypesData = await res[7]?.json()
      const loadTypesData = await res[8]?.json()
      const providersData = await res[9]?.json()

      const duplicateData = await res[10]?.json()
      // if (duplicateId){
      //   const duplicateData = await res[10].json()
      // }

      const rfqData = rfqDataSplit.results[0]
      
      if (!rfqData){
        toast("error",`La cotizacion de radicado : ${radication} no existe, no es posible crear preliquidacion`)
        navigate('/manageQuotes/?tab=pre-liquidation')
      }
      const originCountry = countriesData.find(country => country.name.toLowerCase() === rfqData?.shipping?.origin_boarding_terminal.city.country.name.toLowerCase())
      const destinationCountry = countriesData.find(country => country.name.toLowerCase() === rfqData?.shipping?.destination_boarding_terminal.city.country.name.toLowerCase())
      const shippingMethod = shippingMethodsData.find(method=>method.id === rfqData.shipping.shipping_method)?.name

      // rfqData.shipping.shipping_method = shippingMethod
      setRfq({
        ...rfqData,
        originIso:originCountry?.code,
        destinationIso:destinationCountry?.code,
        shipping:{
          ...rfqData.shipping,
          shippingMethod:shippingMethod,
        }
      })
      setQuotationId(quoteId)
      setUsers(usersData)

      
      let client = rfqData.users.find(user => ['Usuario','Cliente'].includes(user.user?.groups[0]))?.user
      console.log("rfqData :",rfqData)
      if (!client){
        client = rfqData?.users? rfqData?.users[0]: ''
      } 
      const preparedBy = usersData.find(user => user.id === currentUser.idUser)
      const preparedFor = client
      const packagingListData = rfqData?.loads?.map((load,i)=>{
        let convert = 1
        let convertWeight = 1

        if (load.long_unit.trim() == 'cm'){convert = 1/100}
        if (load.long_unit.trim()== 'in'){convert = 1/39.37}
        if (load.unit_weight_item.trim() == 'lb'){convertWeight = 1/2.205}

        return {
          itemId:1+i,
          '#':i+1,
          Descripcion: load?.name,
          Qty: load.amount,
          'Largo(m)': `${(load.long*convert).toFixed(2)}`,
          'Ancho(m)': `${(load.width*convert).toFixed(2)}`,
          'Alto(m)':`${(load.height*convert).toFixed(2)}`,
          'Volumen por pieza(m³)': (load.long * load.width * load.height * convert*convert*convert).toFixed(2),
          'CBM(m³)': (load.long * load.width * load.height * load.amount *convert*convert*convert).toFixed(2),
          'Peso por pieza(Kg)':load.unit_weight * convertWeight,
          'Peso total(Kg)':(load.total_weight * convertWeight).toFixed(2),
          Estiba:'',
        }
      })
      
      const {totalAmount, totalVolume, totalWeightTons, totalValue} = calculateShippingInfo(rfqData.loads)

      const incoterm = incotermsData?.find(term => term?.value === rfqData.business_terms)

      const defaultValues = {
        shipping_method:shippingMethod,
        general:{
          radication:rfqData.radication,
          date:format (new Date(),'dd/MM/yyyy'),
          preparedFor:{
            label: `${client?.first_name || ""} ${client?.last_name || ""} - ${client?.email || ""}`,
            value: client?.id,
          },
          clientEmail:client?.email,
          clientNumber:preparedFor?.phone,
          preparedBy:{
            label:`${preparedBy?.name} ${preparedBy?.last_name}`,
            value:preparedBy?.id,
          },
          preparedByPhone:preparedBy?.phone,
          preparedByEmail:preparedBy?.email,
          salesContact:'',
          salesEmail:'',
          salesPhone:'',
          address:'Cra 55 # 152b-68 Torre A Oficina 1204',
          city:'Bogota',
          country:'Colombia',
        },
        service_details:{
          incoterm:{
            label:incoterm?.name,
            value:incoterm?.id,
          },
          destination_incoterm:{
            label:incoterm?.name,
            value:incoterm?.id,
          },
          valid_until:format(new Date(),'yyyy-MM-dd'),
          move_type: '',
          load_type: '',
          transit_time: '',
          airline: '',
          transshipment_via:'',
        },
        shipping_information:{
          pieces:totalAmount,
          volume:(totalVolume * 1000000).toFixed(2),
          weight:totalWeightTons.toFixed(2),
          chargeable_vol: shippingMethod === 'ocean'? Math.max(totalVolume, totalWeightTons): Number(totalVolume) * 166.67,
          commodity:'',
          dangerous_goods:rfqData?.options?.dangerous===1?{label:'SI',value:true}:{label:'NO',value:false},
          UN:'',
          cargo_value:totalValue,
          TRM:trmData.usd_cop_val,
          currency:{label:'USD', value:1},
          exchange:1,
          usd_eur: trmData.usd_eur,
        },
        packaging_list:packagingListData,
        origin_list:[servicesDefaultData,{...servicesDefaultData,itemId:2},{...servicesDefaultData,itemId:3}],
        net_origin:[netDefaultData],
        net_destination:[netDefaultData],
        net_insurance:[netDefaultData],
        destination_list:[servicesDefaultData,{...servicesDefaultData,itemId:2},{...servicesDefaultData,itemId:3}],
        insurance_list:[servicesDefaultData,{...servicesDefaultData,itemId:2},{...servicesDefaultData,itemId:3}],
        ocean_freight: [servicesDefaultData],
        net_ocean_freight: [netDefaultData],
        net_air_freight: netAirDefaultData,
        PS:[{
          itemId:1,
          PS:"",
          Proveedor: "",
          Currency:"",
        }]
      }

      const currencyOptions = [
        { label: "USD", value: 1 },
        { label: "COP", value: 1 / trmData.usd_cop_val },
        { label: "EUR", value: 1 / trmData.usd_eur },
      ]

      function formatItem(item_type, isDuplicate=false){
        const data = isDuplicate ? duplicateData : preliquidationData
        const list = data?.items?.filter(item => item.item_type === item_type)?.map((item, i)=>{
          return {
            itemId:i+1,
            Concepto: item_type=='OCEAN'?
              {
                label:item.concept,
                value:item.concept,
              }:
              item.concept,
            Factor: item.factor,
            Unit: {
              label:item.unit,
            },
            Trm:item.trm,
            Currency: currencyOptions.find(option => option.label === item.currency),
            Rate: item.item_rate,
            Min: item.min,
            'Total in USD':item.total_in_usd,
          }
        })
        if (list.length){
          return list;
        } else return [servicesDefaultData,{...servicesDefaultData,itemId:2},{...servicesDefaultData,itemId:3}]
      }
      
      function formatNet(item_type, isDuplicate = false){
        const data = isDuplicate ? duplicateData : preliquidationData

        const list = data?.items?.filter(item => item.item_type === item_type)?.map((item, i)=>{
          const net = item.rate || item.ocean_rate || 0
            const provider = providersData?.find(prov=> prov.id === net.provider)
            return {
              itemId:i+1,
              Concepto: net.concept,
              Net: net.net_rate,
              Minima: net.min_rate,
              Total: net.total,
              Profit: net.profit,
              Proveedor: {
                label:provider?.name,
                value:provider?.id,
              },
            }
        })
        
        const filteredList = list.filter(item => item !== undefined)
        if (list.length){
          return list;
        } else return [netDefaultData]
      }
   
      const moveType = moveTypesData?.find(type => type.id == preliquidationData.move_type)
      const loadType = loadTypesData?.find(type => type.id == preliquidationData.load_type)

      const profitShareItems = preliquidationData?.items?.filter(item=> item.item_type == 'PROFIT-SHARE')

      const profitShare = profitShareItems?.map((item,index)=>{
        const data = item.rate
        const provider = providersData.find(prov=>prov.id === data.provider)
        const currency = currencyOptions.find(currency => currency.label == item.currency)
        console.log("PS PROVIDER :",provider)
        return {
          itemId:index+1,
          Currency: currency,
          PS: data?.concept,
          Proveedor: {label: provider?.name, value: provider?.id},
          Rate: data?.net_rate,
        }
      })
      console.log("preliquidationData :",preliquidationData)

      let packaging_list = preliquidationData?.packaging_lists?.map((item,i) => {
        return {
          itemId:1+i,
          '#':item.number,
          Descripcion: item.description,
          Qty: item.qty,
          'Largo(m)': item.length,
          'Ancho(m)': item.width,
          'Alto(m)': item.height,
          'Volumen por pieza(m³)': item.volume_per_piece,
          'CBM(m³)': item.cbm,
          'Peso por pieza(Kg)':item.weight_per_piece,
          'Peso total(Kg)': item.total_weight,
          stowage:{label: item.stowage, value: item.stowage},
        }
      })
      if (!packaging_list?.length){
        packaging_list = [
          {
            itemId:1,
            '#':1,
            Descripcion: "",
            Qty: 0,
            'Largo(m)': 0,
            'Ancho(m)': 0,
            'Alto(m)': 0,
            'Volumen por pieza(m³)': 0,
            'CBM(m³)': 0,
            'Peso por pieza(Kg)':0,
            'Peso total(Kg)': 0,
            stowage:{label:'Si',value:true},
          }
        ]
      }
      const preliquidationValues = (preliquidationId && preliquidationData.service_details) && {
        PS: profitShare,
        shipping_method:shippingMethod,
        general:{
          radication:rfqData.radication,
          date:format (new Date(),'dd/MM/yyyy'),
          preparedFor:{
            label: `${client?.first_name || ""} ${client?.last_name || ""}`,
            value:client?.id,
          },
          clientEmail:client?.email,
          clientNumber:preparedFor?.phone,
          preparedBy:{
            label:`${preparedBy?.name} ${preparedBy?.last_name}`,
            value:preparedBy?.id,
          },
          preparedByPhone:preparedBy?.phone,
          preparedByEmail:preparedBy?.email,
          salesContact:'',
          salesEmail:'',
          salesPhone:'',
          address:'Cra 55 # 152b-68 Torre A Oficina 1204',
          city:'Bogota',
          country:'Colombia',
        },
        service_details:{
          incoterm:{
            label:preliquidationData.service_details.incoterm,
            value:preliquidationData.service_details.incoterm,
          },
          destination_incoterm:{
            label:preliquidationData.service_details.destination_incoterm,
            value:preliquidationData.service_details.destination_incoterm,
          },
          valid_until:preliquidationData.service_details.valid_until,
          move_type: {
            label:moveType?.name,
            value:moveType?.id,
          },
          load_type: {
            label:loadType?.name,
            value:loadType?.id,
          },
          transit_time: preliquidationData.service_details.transit_time,
          airline:{
            label:preliquidationData.service_details.airline,
            value:preliquidationData.service_details.airline,
          },
          transshipment_via:{
            label:preliquidationData.service_details.transshipment_via,
            value:preliquidationData.service_details.transshipment_via,
          },
          loading_point: shippingMethod === 'ground'? preliquidationData.service_details.loading_point : {
            label:preliquidationData.service_details.loading_point,
            value:preliquidationData.service_details.loading_point,
          },
          unloading_point: shippingMethod === 'ground'? preliquidationData.service_details.unloading_point :{
            label:preliquidationData.service_details.unloading_point,
            value:preliquidationData.service_details.unloading_point,
          },
        },

        packaging_list,

        shipping_information:{
          pieces:totalAmount,
          volume:totalVolume.toFixed(2),
          weight:totalWeightTons.toFixed(2),
          chargeable_vol: preliquidationData.shipping_information.chargeable_vol,
          commodity:preliquidationData.shipping_information.commodity,
          dangerous_goods:preliquidationData.shipping_information.dangerous_goods?{label:'SI',value:true}:{label:'NO',value:false},
          UN:preliquidationData.shipping_information.UN,
          cargo_value:preliquidationData.shipping_information.cargo_value,
          TRM:preliquidationData.shipping_information.TRM,
          currency:currencyOptions.find(option => option.label === preliquidationData.shipping_information.currency) || { label: "USD", value: 1 },
          // exchange:1 / currencyOptions.find(option => option.label === preliquidationData.shipping_information.currency)?.value,
          exchange:1,
          usd_eur: trmData.usd_eur,
        },
        origin_list: formatItem("ORIGIN"),
        net_origin: formatNet("ORIGIN"),

        destination_list: formatItem("DESTINATION"),
        net_destination: formatNet("DESTINATION"),

        insurance_list: formatItem("INSURANCE"),
        net_insurance: formatNet("INSURANCE"),
        
        ocean_freight: formatItem("OCEAN"),
        net_ocean_freight: formatNet("OCEAN"),
      }
      
      if (shippingMethod === 'air' && preliquidationData && preliquidationValues){
        preliquidationValues.air_freight = {
          currency:currencyOptions.find(option => option.label === preliquidationData?.air_cargo?.currency) || 0,
          frequency: preliquidationData.air_cargo?.frequency  || 0,
          min: preliquidationData.air_cargo?.min || 0,
          price_per_kg: preliquidationData.air_cargo?.price_per_kg || 0,
          freight_total: preliquidationData.total_freight_usd || 0,
          surcharge_total: preliquidationData.total_fuel_surcharge_usd || 0,
          total: preliquidationData.air_cargo?.total_usd || 0,
          surcharge_min: preliquidationData.air_cargo?.fuel_surcharge_min || 0,
          surcharge_weight: preliquidationData.air_cargo?. fuel_surcharge_kg || 0,
        }
        preliquidationValues.net_air_freight = preliquidationData.air_cargo.air_rate?.length? 
          preliquidationData.air_cargo.air_rate?.map((netItem , index)=> {
            return {
              Concepto: netItem.concept,
              KgCW: netItem.kg,
              Minima: netItem.min_rate,
              Profit: netItem.profit,
              Total: netItem.total,
              itemId: index+1,
            }
          })
        : netAirDefaultData
      }

      const duplicate = preliquidationId? preliquidationValues : defaultValues;
      const duplicateValues = searchParams.get("duplicate") && {  
        ...duplicate,
        origin_list: formatItem("ORIGIN", true),
        net_origin: formatNet("ORIGIN", true),

        destination_list: formatItem("DESTINATION", true),
        net_destination: formatNet("DESTINATION", true),

        insurance_list: formatItem("INSURANCE", true),
        net_insurance: formatNet("INSURANCE", true),
        
        ocean_freight: formatItem("OCEAN", true),
        net_ocean_freight: formatNet("OCEAN", true),
      }
      if (shippingMethod === 'air' && duplicateValues){
        duplicateValues.air_freight = {
          currency:currencyOptions.find(option => option.label === duplicateData?.air_cargo?.currency) || 0,
          frequency: duplicateData.air_cargo?.frequency  || 0,
          min: duplicateData.air_cargo?.min || 0,
          price_per_kg: duplicateData.air_cargo?.price_per_kg || 0,
          freight_total: duplicateData.total_freight_usd || 0,
          surcharge_total: duplicateData.total_fuel_surcharge_usd || 0,
          total: duplicateData.air_cargo?.total_usd || 0,
          surcharge_min: duplicateData.air_cargo?.fuel_surcharge_min || 0,
          surcharge_weight: duplicateData.air_cargo?. fuel_surcharge_kg || 0,
        }
        duplicateValues.net_air_freight = duplicateData?.net_air_freight || netAirDefaultData
      }
      if (duplicateValues){
        methods.reset(duplicateValues)
      } else {
        if (preliquidationId && preliquidationData.service_details){
          methods.reset(preliquidationValues)
        } else {
          methods.reset(defaultValues)
        }
      }
    })
  },[])

  React.useEffect(() => {
    const subscription = methods.watch((value, { name, type }) => {
      // console.log("name :",name)
      // console.log("FORM :",value)
  });
    return () => subscription.unsubscribe();
  }, [methods.watch]);

  
  function onSubmit(){
    const formData = methods.getValues()
    const serviceDetails = formData.service_details
    const shippingInfo = formData.shipping_information
    const originsData = formData.origin_list.map((origin)=>{return {item_type:"ORIGIN", net_key:'net_origin' , ...origin}})
    const destinationsData = formData.destination_list.map((destination)=>{return {item_type:"DESTINATION", net_key:'net_destination', ...destination}})
    const insuranceData = formData.insurance_list.map((insurance)=>{return {item_type:"INSURANCE",net_key:'net_insurance' ,...insurance}})
    const oceanData = formData.ocean_freight.map((freight)=>{return {item_type:"OCEAN",net_key:'net_ocean_freight' ,...freight}})

    let itemsData = originsData.concat(destinationsData, insuranceData, oceanData);

    let filteredItemsData = itemsData.filter(item =>  item.Concepto)

    const items = filteredItemsData.map((item, index) => {
      const nets = formData[item.net_key]
      const net = nets.find(net => (net.Concepto == item.Concepto || net.Concepto == item.Concepto.value ))
      return {
        item_type: item.item_type,
        concept: item.Concepto.value || item.Concepto,
        factor: item.Factor || 0,
        unit: item.Unit?.label || "Ninguno",
        item_rate:item.Rate,
        currency: item.Currency?.label || "USD",
        trm:item.Trm || 1,
        min: item.Min || 0,
        total_in_usd: item['Total in USD'] || 0,
        rate:
        (net?.Concepto?.value || net?.Concepto) && (net?.Total && net?.Profit)?
        {
          concept: net?.Concepto.value || net?.Concepto,
          net_rate: net?.Net || 0,
          min_rate: net?.Minima || 0,
          total: net?.Total || 0,
          profit:  net?.Profit || 0,
          provider: net?.Proveedor?.value,
        } : null
      }
    })

    const PSItems = formData?.PS?.map(rate => {
      return {
        item_type: 'PROFIT-SHARE',
        concept: 'PS',
        factor: 1,
        unit: "Ninguno",
        item_rate:0,
        currency:"USD",
        trm:1,
        min: 0,
        total_in_usd:0,
        rate: {
          concept: rate?.PS,
          net_rate: rate?.Rate || 0,
          min_rate:0,
          total: rate.Rate * rate.Currency.value,
          profit: -rate.Rate * rate.Currency.value,
          provider: rate?.Proveedor?.value,
        }
      }
    })
    let filteredPSItems = PSItems.filter(item => item.rate.total)

    let submitData = {
      user:currentUser.idUser,
      move_type:formData.service_details?.move_type?.value,
      load_type:formData.service_details?.load_type?.value,

      service_details:{
        ...formData.service_details,
        loading_point:serviceDetails?.loading_point?.value || serviceDetails?.loading_point ,
        unloading_point:serviceDetails?.unloading_point?.value || serviceDetails?.unloading_point,
        transshipment_via:serviceDetails?.transshipment_via?.value,
        move_type:serviceDetails.move_type?.label,
        load_type:serviceDetails.load_type?.label,
        incoterm:serviceDetails.incoterm?.label,
        destination_incoterm:serviceDetails.destination_incoterm?.label,
        airline:serviceDetails.airline?.label,
      },
      shipping_information:{
        ...shippingInfo,
        dangerous_goods:shippingInfo?.dangerous_goods?.value,
        currency:shippingInfo.currency?.label,
        UN:shippingInfo.UN || 'No aplica',
      },

      quotation_ids: [quotationId],

      items :filteredPSItems?.length?[
        ...items,
        ...filteredPSItems,
      ]:
      items,

      packaging_lists:formData.packaging_list?.map(e=> { 
        return{
          qty:e.Qty,
          number:e['#'],
          description:e['Descripcion'],
          length: e['Largo(m)'],
          width: e['Ancho(m)'],
          height: e['Alto(m)'],
          volume_per_piece: e['Volumen por pieza(m³)'],
          cbm: e['CBM(m³)'],
          weight_per_piece: e['Peso por pieza(Kg)'],
          total_weight: e['Peso total(Kg)'],
          stowage: e['Estiba']?.label,
        }
      }), 
    }

    if (formData.shipping_method==='air' && formData.air_freight && formData.air_freight?.total && formData.air_freight?.total){
      submitData.air_cargo = {
          currency: formData.air_freight?.currency?.label, 
          frequency:formData.air_freight?.frequency,
          min:formData.air_freight?.min,
          price_per_ton:(formData.air_freight?.price_per_kg  || 0)/ 1000,
          price_per_kg:formData.air_freight?.price_per_kg || 0,
          total_freight_usd:formData.air_freight?.freight_total ? Number(formData.air_freight?.freight_total) : 0,
          total_fuel_surcharge_usd:formData.air_freight?.surcharge_total || 0,
          total_usd:formData.air_freight?.total || 0,
          fuel_surcharge_min:formData.air_freight?.surcharge_min,
          fuel_surcharge_kg: formData.air_freight?.surcharge_weight || 0,
          air_rate : formData.net_air_freight?.map(item=>{
            return {
              concept:item.Concepto,
              kg:Number(item['KgCW']) || 0,
              net_rate: Number(item?.Net )|| 0,
              min_rate: Number(item?.Minima) || 0,
              total: Number(item?.Total) || 0,
              profit:  Number(item?.Profit )|| 0,
              // provider: item?.Proveedor?.value || 179,
            }
          })
      }
    }
    console.log("submitData :",submitData)
    if (submitData.items.length < 1 && !submitData?.air_cargo?.total_freight_usd){
      toast("warn","La preliquidacion no tiene ningun costo");
      return
    }


    if (preliquidationId){
      editPreliquidation(token, submitData, preliquidationId).then(()=>{
        toast("success", "Preliquidacion modificada")
        navigate(`/manageQuotes/?tab=pre-liquidation&selectId=${submitData?.quotation_ids[0]}`)
      }).catch(err=>{
        console.log("err :",err)
        toast("error", `
          ${err}
        `)
      })
    } else {
      createPreliquidation(token, submitData).then(()=>{
        toast("success", "Preliquidacion creada con exito")
        navigate(`/manageQuotes/?tab=pre-liquidation&selectId=${submitData?.quotation_ids[0]}`)
      }).catch(err=>{
        console.log("err :",err)
        toast("error", `
          ${err}
        `)
      })
    }
    
  }

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {
            steps.map((step, i) => (
              <StyledStep key={i}>
                <StepLabel>{step.name}</StepLabel>
              </StyledStep>
            ))
          }
        </Stepper>
        <FormProvider {...methods}>
          <ViewsHandler
            activeView={activeStep}
          >
            <General trigger={trigger} usersData={users} rfqData={rfq}/>
            <Shipment trigger={trigger} rfqData={rfq}/>
            <Costs rfqData={rfq}/>
          </ViewsHandler>

          <div style={{display:'flex',justifyContent:'space-between',margin:'20px'}}>
            <Button disabled={activeStep===0} text={"Atras"} outline handler={() => {
              setActiveStep(activeStep-1)
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}/>
            {
              activeStep === 2?
              <Button disabled={!isValid} bgColor='primary_500' textColor='neutral_50' bold text="Guardar" outline type='submit'/>:
              <Button disabled={
                  activeStep===2 
                  // || (!isValid || Object.keys(errors).length>0)
                }
                text="Continuar" 
                outline 
                handler={() => {
                  if (!isValid ){
                    trigger()
                    toast('warn','Hay un error en el formulario, por favor corriga e intente nuevamente')
                  } else {
                    setActiveStep(activeStep+1)
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }
                }}
              />
            }
          </div>
        </FormProvider>
    </Container>
  )
}