import React, { useEffect, useState } from "react";
import { styled } from "@nextui-org/react";
import theme from "../../../theme";
import { Step, StepLabel, Stepper } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import camelCaseToSnakeCase from "../../../services/case.services";
import { sendQuotation, editQuotationByServiceSplit, sendCodeParticipant, getServiceSplitRadication, editPurchaseOrderByRadication} from "../../../services/quotation.services";
import { useSelector } from "react-redux";
import toast from "../../../components/Toast/ToastTypes";
import { getLoadTypes } from "../../../services/load.services";
import { getBusinessTerms } from "../../../services/businessTerms.services";
import {getCountriesNew} from "../../../services/countries.services";
import { getShippingMethods } from "../../../services/shippingMethods.services";
import { getIndustries } from "../../../services/industries.services";
import { FormProvider, useForm } from "react-hook-form";
//Components
import ViewsHandler from "../../../components/ViewsHandler/ViewsHandler";
import Button from "../../../components/Button/Button";
import Liquidation from "./Liquidation/Liquidation";
import Commerce from "./Commerce/Commerce";
import Negotiation from "./Negotiation/Negotation";
import Send from "./Send/Send";
import Load from "./Load/Load";
import Additional from "./Additional/Additional";
import Documents from "./Documents/Documents";
import {useTranslation } from "react-i18next";
import QuickRFQModal from './QuickRFQ/QuickRFQModal'

//Constants
import { liquidationFormSteps, projectMethods, generalMethods} from "../../../constants";
import { getUsers } from "../../../services/user.services";
import useMediaQueries from "../../../hooks/useMediaQueries";

const StyledQuotation = styled("div", {
  [`.${theme} &`]: {
    padding: "1rem",
    minHeigh: "1000px",
  },
});

const StyledStep = styled(Step, {
  [`.${theme} &`]: {
    minWidth:'0',
    [".MuiStepLabel-root"]: {
      position: "relative",
      zIndex: "1",
      [".MuiStepLabel-iconContainer"]: {
        backgroundColor: "$primary_50",
        ["svg"]: {
          borderRadius: "1rem",
          color: "$primary_300",
          ["circle"]: {
            color: "$primary_300",
          },
          ["text"]: {
            fill: "$neutral_50",
          },
        },
      },
      [".MuiStepLabel-iconContainer.Mui-disabled"]: {
        backgroundColor: "$primary_50",
        ["svg"]: {
          border: "solid 1px $primary_300",
          borderRadius: "1rem",
          ["circle"]: {
            color: "$primary_50",
          },
          ["text"]: {
            fill: "$primary_300",
          },
        },
      },
      [".MuiStepLabel-label, .MuiStepIcon-text"]: {
        fontFamily: "inherit",
      },
      [".MuiStepLabel-label.Mui-completed, .MuiStepLabel-label.Mui-active"]: {
        color: "$primary_300",
      },
      [".MuiStepLabel-label.Mui-disabled"]: {
        color: "$primary_100",
      },
    },
    [".MuiStepConnector-root"]: {
      zIndex: "0",
      left: "-50%",
      right: "50%",
      ["span"]: {
        border: "solid 1.5px $primary_300",
      },
    },
    "@media (max-width: 1000px)":{
      [".MuiStepLabel-label, .MuiStepIcon-text"]:{
        fontSize:'10px',
      },
      ["svg"]:{
        width:"25px",
        height:"25px"
      }
    },
    "@media (max-width: 600px)":{
      [".MuiStepLabel-label"]:{
        display:'none'
      },
    }
  },
});

const StyledFooterContainer = styled("div", {
  [`.${theme} &`]: {
    position: "sticky",
    display: "flex",
    justifyContent: "center",
    bottom: "0",
    width: "100%",
    height: "75px",
    [".footer"]: {
      backgroundColor: "$neutral_50",
      width: "90%",
      borderRadius: "8px 8px 0px 0px",
      padding: "1rem",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      boxShadow: "$elevation_1",
      [".moveSteps"]: {
        display: "flex",
        gap: "1rem",
      },
    },
  },
});

function NewQuotation() {
  const { t: translate } = useTranslation("Quotation");
  const {sm,md} = useMediaQueries()

  const navigate = useNavigate();
  const [radication, setRadication] = useState();
  const [openModal, setOpenModal] = useState()

  const user = useSelector((state) => state.user);
  const token = user.token;
  const [searchParams] = useSearchParams();

  const [activeStep, setActiveStep] = useState(0);
  const [disabledContinue, setDisabledContinue] = useState(true);
  const [disabledSave, setDisabledSave] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [industries, setIndustries] = useState([]);
  const [users, setUsers] = useState([]);
  const [loadTypes, setLoadTypes] = useState([]);
  const [businessTerms, setBusinessTerms] = useState([]);
  const [commerceTypes, setCommerceTypes] = useState([]);
  const [countries, setCountries] = useState([]);
  const [shippingMethods, setShippingMethods] = useState([]);
  const [loadTypeLabel, setLoadTypeLabel] = useState(null);
  const [steps, setSteps] = useState(liquidationFormSteps.General);
  const [liquidation, setLiquidation] = useState({});
  const [commerce, setCommerce] = useState({});
  const [negotiation, setNegotiation] = useState({});
  const [load, setLoad] = useState({});
  const [documents, setDocuments] = useState();
  const [client, setClient] = useState();
  const [serviceSplit, setserviceSplit] = useState({});
  const methods = useForm({
    defaultValues: {
      commerce: {
        commerceType: "import",
        shippingMethod: {
          value: 1,
          text: "Aéreo",
          icon: "Aereo",
        },
      },
      load: {
        0: {
          insurance: false,
          longUnit: "cm",
          widthUnit: "cm",
          heightUnit: "cm",
          totalWeightUnit: "kg",
          unitWeightItem: "kg",
        },
      },
      negotiation: {
        businessTerm: 1,
      },
      additional: {
        imo: false,
        // permissions:false,
        temperatureControl: false,
        dataLogger: false,
        customs: false,
        remountable: false,
        arancelaryPosition: false,
        routeStudy: false,
        securityAnalysis: false,
        lashingSurvey: false,
        workCommunities: false,
        additionalSpecifications: "",
      },
    },
  });

  const handleLoadTypeLabelChange = (newLabel) => {
    setLoadTypeLabel(newLabel);
  };
  const handleBack = () => {
    setActiveStep((preActiveStep) => preActiveStep - 1);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleNext = () => {
    const label = methods.watch().liquidation.loadType.label;
    if (label === "Cotizacion Rapida") {
      setOpenModal(true);
    } else {
      setActiveStep((preActiveStep) => preActiveStep + 1);
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const [HideSave, setHideSave] = useState(true);

  const save = async () => {
    setHideSave(false);
    const submitData = camelCaseToSnakeCase(methods.getValues());

    const industryName = users.find((user) => user.id === client?.value)?.industry || users.find((e) => e.id === user?.idUser)?.industry;

    const industryId = industries.find(
      (industry) => industry.name === industryName
    )?.id;

    const oldSubmitData = {
      liquidation: { load_type: submitData.liquidation.load_type.value },
      commerce: {
        commerce_type: submitData.commerce.commerce_type,
        industry: industryId || "",
        another_industry: industryId ? "" : industryName,
      },
      negotiation: submitData.negotiation,
      send: submitData.send,
      load: submitData.load,
      additional: submitData.additional,
      documents: {},
      client:client?.value,
    };
    if (!oldSubmitData.send.delivery_address) {
      oldSubmitData.send.delivery_address = "Ninguno";
    }
    if (!oldSubmitData.send.pick_up_address) {
      oldSubmitData.send.pick_up_address = "Ninguno";
    }
    if (!oldSubmitData.send.pick_up_comments) {
      oldSubmitData.send.pick_up_comments = "Ninguno";
    }
    if (!oldSubmitData.send.delivery_comments) {
      oldSubmitData.send.delivery_comments = "Ninguno";
    }
    if (!oldSubmitData.send.origin_boarding_terminal) {
      oldSubmitData.send.origin_boarding_terminal = "Ninguno";
    }
    if (!oldSubmitData.send.destination_boarding_terminal) {
      oldSubmitData.send.destination_boarding_terminal = "Ninguno";
    }
    if (!oldSubmitData.additional.purchase_order) {
      oldSubmitData.additional.purchase_order = "Ninguna";
    }

    oldSubmitData.send.shipping_method =
      submitData.commerce.shipping_method.value;
    oldSubmitData.send.origin_city = submitData.send.origin_boarding_terminal;
    oldSubmitData.send.destination_city =
      submitData.send.destination_boarding_terminal;

    let keys = Object.keys(oldSubmitData.load);
    if (oldSubmitData.send.shipping_method === 2) {
      keys.forEach((key) => {
        if (!oldSubmitData.load[key].contenedorCompleto) {
          if (oldSubmitData.load[key].container_size) {
            oldSubmitData.load[key].long =
              oldSubmitData.load[key].container_size.measurements?.long;
            oldSubmitData.load[key].height =
              oldSubmitData.load[key].container_size.measurements?.height;
            oldSubmitData.load[key].width =
              oldSubmitData.load[key].container_size.measurements?.width;
            oldSubmitData.load[key].width_unit = "m";
            oldSubmitData.load[key].height_unit = "m";
            oldSubmitData.load[key].long_unit = "m";
            oldSubmitData.load[key].container_size =
              oldSubmitData.load[key].container_size.value;
          }
        }
      });
    }
    keys.forEach((key) => {
      delete oldSubmitData.load[key].contenedor_completo;
    });
    console.log("oldSubmitData :", oldSubmitData);
    sendQuotation(oldSubmitData, token)
      .then(async (res) => {
        const data = await res.json();
        if (res.ok) {
          if (client) {
            getServiceSplitRadication(data.radication_number, token).then(
              async (res) => {
                const serviceData = await res.json();
                sendCodeParticipant({ email: client.email, radication: data.radication_number }, token).then(() => {
                    navigate( `/quotation/detail/?radication=${data.radication_number}&id=${serviceData.results[0].id}&created=quotation`);
                }).catch(() => {
                  navigate(`/quotation/detail/?radication=${data.radication_number}&id=${serviceData.results[0].id}&created=quotation`);
                });
              }
            );
          } else {
            getServiceSplitRadication(data.radication_number, token).then(
              async (res) => {
                const serviceData = await res.json();
                navigate(`/quotation/detail/?radication=${data.radication_number}&id=${serviceData.results[0].id}&created=quotation`);
              }
            );
          }
        } else {
          setHideSave(true);
          Object.keys(data.errors).forEach((input) => {
            toast("error", data.errors[input]);
          });
        }
      }).catch((error) => {
        setHideSave(true);
        console.log(error);
      });
  };
  const formatLoads = (editData, loads) => {
    Object.keys(editData.load).forEach((loadKey) => {
      const loadIndex = parseInt(loadKey);
      const frontLoad = editData.load[loadKey];

      if (loads[loadIndex]) {
        loads[loadIndex].name = frontLoad.loadName;
        loads[loadIndex].value = parseInt(frontLoad.loadValue);
        loads[loadIndex].long = parseFloat(frontLoad.long);
        loads[loadIndex].width = parseFloat(frontLoad.width);
        loads[loadIndex].height = parseFloat(frontLoad.height);
        loads[loadIndex].amount = parseInt(frontLoad.amount);
        loads[loadIndex].unit_weight = parseInt(frontLoad.unitWeight);
        loads[loadIndex].total_weight = parseInt(frontLoad.totalWeight);
        loads[loadIndex].insurance = frontLoad.insurance ? 1 : 0;
        loads[loadIndex].containers = frontLoad.containerSize && frontLoad.containerSize.value ? frontLoad.containerSize.value : null;
        loads[loadIndex].height_unit = frontLoad.heightUnit ?? 'cm';
        loads[loadIndex].long_unit = frontLoad.longUnit ?? 'cm';
        loads[loadIndex].width_unit = frontLoad.widthUnit ?? 'cm';
        loads[loadIndex].unit_weight_item = frontLoad.unitWeightItem ?? 'kg';
        loads[loadIndex].total_weight_unit = frontLoad.totalWeightUnit ?? 'kg';

      } else {
        loads.push({
          name: frontLoad.loadName,
          value: parseInt(frontLoad.loadValue),
          long: parseFloat(frontLoad.long),
          width: parseFloat(frontLoad.width),
          height: parseFloat(frontLoad.height),
          amount: parseInt(frontLoad.amount),
          unit_weight: parseInt(frontLoad.unitWeight),
          total_weight: parseInt(frontLoad.totalWeight),
          insurance: frontLoad.insurance ? 1 : 0,
          containers: frontLoad.containerSize && frontLoad.containerSize.value ? frontLoad.containerSize.value : null,
          productload_set: [],
          height_unit: frontLoad.heightUnit ?? "cm",
          long_unit: frontLoad.longUnit ?? "cm",
          width_unit: frontLoad.widthUnit ?? "cm",
          unit_weight_item: frontLoad.unitWeightItem ?? "kg",
          total_weight_unit: frontLoad.totalWeightUnit ?? "kg",
        });
      }
    });
    return loads;
  };

  const saveChanges = async () => {
    let editData = methods.watch();
    if (editData.load) {
      serviceSplit.loads = serviceSplit.loads || {};
      serviceSplit.loads = await formatLoads(editData, serviceSplit.loads)
    }
    if (editData.negotiation) {
      serviceSplit.business_term = editData.negotiation.businessTerm;
    }
    if (editData.additional) {
      serviceSplit.service_options = serviceSplit.service_options || {};
      serviceSplit.purchase_order = serviceSplit.purchase_order || {};
      serviceSplit.service_options.packaging = editData.additional.packaging ? "True" : "False";
      serviceSplit.service_options.imo = editData.additional.imo ? "True" : "False";
      serviceSplit.service_options.temperature_control = editData.additional.temperatureControl ? 1 : 0;
      serviceSplit.service_options.data_logger = editData.additional.dataLogger ? 1 : 0;
      serviceSplit.service_options.customs = editData.additional.customs ? 1 : 0;
      serviceSplit.service_options.remountable = editData.additional.remountable ? 1 : 0;
      serviceSplit.service_options.arancelary_position = editData.additional.arancelaryPosition ? 1 : 0;
      serviceSplit.service_options.route_study = editData.additional.routeStudy ? 1 : 0;
      serviceSplit.service_options.security_analysis = editData.additional.securityAnalysis ? 1 : 0;
      serviceSplit.service_options.lashing_survey = editData.additional.lashingSurvey ? 1 : 0;
      serviceSplit.service_options.work_communities = editData.additional.workCommunities ? 1 : 0;
      serviceSplit.service_options.additional_specifications = editData.additional.additionalSpecifications === "" ? "No additional specitications" : editData.additional.additionalSpecifications;
      serviceSplit.purchase_order.purchaseNumber = editData.additional.purchaseOrder
    }
    if (editData.liquidation && editData.liquidation.loadType) {
      serviceSplit.loads.forEach((load, index) => {
        serviceSplit.loads[index].load_type = {
          id: editData.liquidation.loadType.value,
          name: editData.liquidation.loadType.label,
        };
      });
    }
    if (editData.commerce) {
      serviceSplit.shipping = serviceSplit.shipping || {};
      serviceSplit.business_type = editData.commerce.commerceType;
      serviceSplit.shipping.shipping_method =
        editData.commerce.shippingMethod.value;
    }
    if (editData.send) {
      serviceSplit.shipping = serviceSplit.shipping || {};
      serviceSplit.shipping.delivery_address = editData.send.deliveryAddress;
      serviceSplit.shipping.delivery_comments = editData.send.deliveryComments;
      serviceSplit.shipping.pickup_address = editData.send.pickUpAddress;
      serviceSplit.shipping.pickup_comments = editData.send.pickUpComments;
      serviceSplit.shipping.destination_boarding_terminal = serviceSplit.shipping.destination_boarding_terminal || {city: {country: {}}};
      serviceSplit.shipping.origin_boarding_terminal = serviceSplit.shipping.origin_boarding_terminal || {city: {country: {}}};
      serviceSplit.shipping.destination_boarding_terminal.city.name = editData.send.destinationBoardingTerminal;
      serviceSplit.shipping.origin_boarding_terminal.city.name = editData.send.originBoardingTerminal;
      serviceSplit.shipping.destination_boarding_terminal.city.name = editData.send.destinationBoardingTerminal;
      serviceSplit.shipping.origin_boarding_terminal.city.country.name = countries.find(country => country.id === editData.send.originCountry)?.name
      serviceSplit.shipping.destination_boarding_terminal.city.country.name = countries.find(country => country.id === editData.send.destinationCountry)?.name
      serviceSplit.shipping.travel_distance = "1234.567"
    }
    editQuotationByServiceSplit(serviceSplit, token).then(res => {
      res.json().then(data => {
      const newRadication = data.radication;
      getServiceSplitRadication(newRadication, token).then(async res => {
        const serviceData = await res.json()
        const service = serviceData.results[0]
        await sendCodeParticipant({
          email: client?.email,
          radication: newRadication
        }, token)
        await editPurchaseOrderByRadication(serviceSplit.purchase_order,newRadication,token)
        navigate(`/quotation/detail/?radication=${newRadication}&id=${service.id}&created=quotation`)
      })
      })
    }).catch(err=>{console.log("err :",err); toast('error',"Ha habido un error al editar")})
  }
  
  // console.log("Form :", methods.watch())
  useEffect(() => {
   
    setCommerceTypes([
      { value: "import", name: translate("importation") },
      { value: "export", name: translate("exportation") },
      { value: "customs", name: translate("customs") },
      { value: "triangular", name: translate("triangulate") },
    ]);

    let radication = searchParams.get("radication");
   /*  if (radication && radication.startsWith('QRFQ')) {
      radication = 'VAL' + radication.substring(4);
  } */

    setRadication(radication);
    const asyncTasks = [
      getLoadTypes(token),
      getBusinessTerms(token),
      getCountriesNew(token),
      getShippingMethods(token),
      getIndustries(token),
      getUsers(token),
    ];
    if (radication) {
      asyncTasks.push(getServiceSplitRadication(radication, token));
    }
    Promise.all(asyncTasks).then(async (res) => {
      const loadTypesData = await res[0].json();
      const businessTermsData = await res[1].json();
      const countriesData = await res[2].json();
      const shippingMethodsData = await res[3].json();
      const industriesData = await res[4].json();
      const usersData = await res[5].json();

      setLoadTypes(loadTypesData);
      setBusinessTerms(businessTermsData);
      setCountries(countriesData);
      setShippingMethods(shippingMethodsData);
      setIndustries(industriesData);
      setUsers(usersData);
      if (radication) {
        const result = await res[6].json();
        const serviceSplit = result.results[0];
        setserviceSplit(serviceSplit);
        const shipping = serviceSplit.shipping.shipping_method;
        const shipping_method =
          generalMethods.find((shi) => shi.value == shipping) ??
          projectMethods.find((shi) => shi.value == shipping);
        const loads = {};
        let cont = 0;
        serviceSplit.loads.forEach((load) => {
          loads[`${cont}`] = {
            containerSize: { value: load.containers },
            loadName: load.name,
            loadValue: load.value,
            long: load.long ? load.long.toString() : null,
            width: load.width ? load.width.toString() : null,
            height: load.height ? load.height.toString() : null,
            amount: load.amount ? load.amount.toString() : null,
            unitWeight: load.unit_weight ? load.unit_weight.toString() : null,
            totalWeight: load.total_weight,
            insurance: load.insurance === 1,
        };
          cont += 1;
        });
        setLoad({ load: loads });
        let user = serviceSplit.users.find(
          (user) =>
            user.user?.groups.includes("Cliente") ||
            user.user?.groups.includes("Usuario")
        );
        if (user) {
          user = {
            label: `${user.user.email} - ${user.user.first_name} ${user.user.last_name}`,
            value: user.user.id,
          };
          setClient(user);
        } else {
          toast("info", "Esta cotización no tiene un cliente asignado");
        }

        const formattedQuoteData = {
          load: loads,
          negotiation: {
            businessTerm: serviceSplit.business_term,
          },
          additional: {
            packaging: serviceSplit.service_options.packaging,
            imo: serviceSplit.service_options.imo === "True",
            temperatureControl:
              serviceSplit.service_options.temperature_control === 1,
            dataLogger: serviceSplit.service_options.data_logger === 1,
            customs: serviceSplit.service_options.customs === 1,
            remountable: serviceSplit.service_options.remountable === 1,
            arancelaryPosition:
              serviceSplit.service_options.arancelary_position === 1,
            routeStudy: serviceSplit.service_options.route_study === 1,
            securityAnalysis:
              serviceSplit.service_options.security_analysis === 1,
            lashingSurvey: serviceSplit.service_options.lashing_survey === 1,
            workCommunities:
              serviceSplit.service_options.work_communities === 1,
            additionalSpecifications:
              serviceSplit.service_options.additional_specifications,
            purchaseOrder: serviceSplit.purchase_order.purchaseNumber,
          },
          liquidation: {
            loadType: serviceSplit.loads[0].load_type.name,
          },
          commerce: {
            commerceType: serviceSplit.business_type,
            shippingMethod: shipping_method,
          },
          send: {
            originCountry: countriesData.find(
              (country) =>
                country.name ===
                serviceSplit.shipping.origin_boarding_terminal.city.country.name
            )?.id,
            pickUpAddress: serviceSplit.shipping.pickup_address,
            pickUpComments: serviceSplit.shipping.pickup_comments,
            originBoardingTerminal:
              serviceSplit.shipping?.origin_boarding_terminal.city.name,
            destinationBoardingTerminal:
              serviceSplit.shipping?.destination_boarding_terminal.city.name,
            destinationCountry: countriesData.find(
              (country) =>
                country.name ===
                serviceSplit.shipping.destination_boarding_terminal.city.country
                  .name
            )?.id,
            deliveryAddress: serviceSplit.shipping.delivery_address,
            deliveryComments: serviceSplit.shipping.delivery_comments,
          },
        };
        methods.reset(formattedQuoteData);
      }
    });
  }, []);

  return (
    <div>
      <StyledQuotation>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((step, i) => (
            <StyledStep key={i}>
              <StepLabel>{step.name}</StepLabel>
            </StyledStep>
          ))}
        </Stepper>
        <FormProvider {...methods}>
          <ViewsHandler
            activeView={activeStep}
          >
            <Liquidation setDisabledContinue={setDisabledContinue} loadTypes={loadTypes} onLabelChange={handleLoadTypeLabelChange}/>
            <Commerce shippingMethods={shippingMethods} setDisabledContinue={setDisabledContinue} commerceTypes={commerceTypes} loadTypes={loadTypes} />
            <Negotiation setDisabledContinue={setDisabledContinue} negotiation={negotiation} commerceType={commerce?.commerceType} businessTerms={businessTerms} />
            <Send loadTypes={loadTypes} countries={countries} shippingMethods={shippingMethods} setDisabledContinue={setDisabledContinue} />
            <Load setDisabledContinue={setDisabledContinue} countries={countries} activeStep={activeStep} load={load} setLoad={setLoad} loadType={liquidation?.loadType} loadTypes={loadTypes} />
            <Additional client={client} setClient={setClient} countries={countries} submitting={submitting} loadTypes={loadTypes} setDisabledSave={setDisabledSave} />
            <Documents setDocuments={setDocuments} />
          </ViewsHandler>
        </FormProvider>
      </StyledQuotation>

      <StyledFooterContainer>
        <div className='footer'>
          <Button handler={() => navigate('/quotation-requests')} size = {sm?"xs":"sm"} text='Salir' bgColor='primary_50' startIcon={{ name: 'ArrowLeft', size: '13' }} outline bold> </Button>
          <div className='moveSteps'>
            <Button handler={handleBack} size = {sm?"xs":"sm"} text='Atrás' bgColor='primary_50' disabled={activeStep === 0} outline bold></Button>
            {
              activeStep === steps.length - 1 ?
                radication ? <Button disabled={disabledContinue} text='Guardar cambios' bgColor='primary_400' textColor='neutral_50' handl bold
                  handler={saveChanges}
                />
               : HideSave ? 
                <Button
                  size = {sm?"xs":"sm"}
                  handler={save}
                  disabled={disabledSave}
                  text={translate("save")}
                  bgColor="primary_400"
                  textColor="neutral_50"
                  bold
                ></Button>
               : 
                <Button
                  size = {sm?"xs":"sm"}
                  text={translate("save")}
                  bgColor="disabled"
                  textColor="neutral_50"
                  bold
                ></Button>
              
             : 
              <Button
                size = {sm?"xs":"sm"}
                handler={handleNext}
                text={translate("save")}
                bgColor="primary_400"
                disabled={disabledContinue}
                textColor="neutral_50"
                bold
              ></Button>
            }
          </div>
        </div>
        <QuickRFQModal 
                open={openModal} 
                setOpen={setOpenModal} 
              
             
            />
      </StyledFooterContainer>
    </div>
  );
}
export default NewQuotation;