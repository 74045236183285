import React, { useState, useRef, useEffect } from "react";
import { styled } from "@nextui-org/react";
import theme from "../../../../theme";
import { useFormContext, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
//Componentes
import PageTitle from "../../../../components/PageTitle/PageTitle";
import InputSelect from "../../../../components/Select/inputSelect";
import GeneralCard from "./GeneralCard";
import ProjectCard from "./ProjectCard";
import QuickQuoteCard from "./QuickQuoteCard";
import { DirectionsMap } from "../../../../components/directionsMap/DirectionsMap";

const StyledLiquidation = styled("div", {
  [`.${theme} &`]: {
    margin: "3rem 8rem 0rem 8rem",
    padding: "3rem 8rem",
    backgroundColor: "$neutral_50",
    borderRadius: "8px",
    boxShadow:'$elevation_1',
    ["aside"]: {
      color: "$primary_200",
    },
    [".separate"]: {
      margin: "1rem 1rem",
      backgroundColor: "$neutral_100",
    },
    "@media (max-width: 1200px)":{
      margin:"2rem 1rem",
      padding:"2rem",
      fontSize:'12px',
    },
    "@media (max-width: 400px)":{
      // margin:"10px",
      marginTop:"3rem",
      padding:"20px",
      fontSize:'10px',
    }
  },
});

function Liquidation({ loadTypes, setDisabledContinue }) {
  const nameForm = "liquidation";
  console.log("loadTypes :", loadTypes);
  const {
    control,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { t: translate } = useTranslation("Quotation");

  useEffect(() => {
    setDisabledContinue(true);
    if (getValues(`${nameForm}.loadType`)) {
      if (typeof watch(`${nameForm}.loadType`) === "string") {
        const defaultValue = loadTypes.find(
          (type) => type.name === watch(`${nameForm}.loadType`)
        );
        console.log("defaultValue :", defaultValue);
        setValue(`${nameForm}.loadType`, {
          label: defaultValue.name,
          value: defaultValue.id,
        });

      }
      setDisabledContinue(false);
    } else {
      setDisabledContinue(true);
    }
  }, [watch(`${nameForm}.loadType`)]);

  return (
    <StyledLiquidation>
      <div className="d-flex flex-column gap-1">
        <PageTitle>{translate("liquidation")}</PageTitle>
        <aside>{translate("liquidation_type_description")}</aside>
        <main>
          <Controller
            control={control}
            name={`${nameForm}.loadType`}
            rules={{ required: "Este campo es obligatorio" }}
            render={({ field }) => (
              <InputSelect
                {...field}
                name={`${nameForm}.loadType`}
                label="Selecciona tipo de carga"
                options={loadTypes.map((type) => {
                  return {
                    label: type.name,
                    value: type.id,
                  };
                })}
              />
            )}
          ></Controller>
        </main>
      </div>
      {getValues(`${nameForm}.loadType`) !== "" && (
        <div>
          {(() => {
  const label = watch(`${nameForm}.loadType.label`);
  if (label === "Carga General") {
    return <GeneralCard />;
  } else if (label === "Cotizacion Rapida") {
    return <QuickQuoteCard />;
  } else {
    return <ProjectCard />;
  }
})()}
        </div>
      )}
    </StyledLiquidation>
  );
}
export default Liquidation;
