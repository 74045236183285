import React, { useEffect, useState } from "react";
import theme from "../../../theme";
import { Avatar, styled } from "@nextui-org/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getUserAuth } from "../../../services/auth.services";
import "moment/locale/es";
import { useTranslation } from "react-i18next";
// Components
import Icon from "../../../components/Icon/Icon";
import Button from "../../../components/Button/Button";
import Marquee from "react-fast-marquee";
import { getTrms } from "../../../services/kpis.services";
import logo from "../../../assets/img/val-big.png";
import { format } from "date-fns";
import DropdownAvatar from "../../../components/DropdownAvatar/DropdownAvatar";
import useMediaQueries from "../../../hooks/useMediaQueries";

function Navbar({ token }) {
  const { t: translate } = useTranslation("Tools");
  const [trms, setTrms] = useState({});
  const location = useLocation();
  const { md, lg } = useMediaQueries();
  const [user, setUser] = useState({
    name: "",
    lastName: "",
  });
  const { i18n } = useTranslation();

  const NavContainer = styled("div", {
    [`.${theme} &`]: {
      backgroundColor: "$neutral_50",
      height: "72px",
      width:'100%',
      display: "flex",
      justifyContent: "space-between",
      gap:'20px',
      alignItems: "center",
      padding: "2rem",
      position: "sticky",
      top: "0px",
      right: "0px",
      zIndex: 800,
    },
    [".tools"]: {
      display: "flex",
      alignItems: "center",
      gap: "0.5rem",
      textDecoration: `${
        location.pathname === "/tools" ? "underline" : "none"
      }`,
      color: `${
        location.pathname === "/tools" ? "$secondary_300" : "inherent"
      }`,
    },
    [".notificaciones"]: {
      display: "flex",
      alignItems: "center",
      marginRight: "20px",
      textDecoration: "none",
    },
    [".profile"]: {
      gap: "1rem",
      display: "flex",
      alignItems: "center",
      color: "$neutral_50",
    },
  });

  useEffect(() => {
    getTrms(token).then(async (res) => {
      const trmData = await res.json();
      setTrms(trmData);
    });
    getUserAuth(token).then(({ user }) => {
      setUser(user);
    });
  }, []);

  return (
    <NavContainer>
      <div style={{ display: "flex" }}>
        <Link to="/tools" className="tools">
          <Icon
            name="Herramientas"
            color={location.pathname === "/tools" ? "#EB9D3A" : undefined}
          />
          {md && translate("title")}
        </Link>
      </div>
      {md && <Marquee pauseOnHover>
        {/* <img
          src={logo}
          className="logo"
          alt=""
          height="50px"
          style={{ margin: "10px" }}
        /> */}
        
        <div style={{ display: "flex", gap: "40px", fontSize:'14px'}}>
          <span>TRM {trms.fecha}</span>
          <span>
            <b>USD/COP</b>: {trms.usd_cop && trms.usd_cop.toFixed(2)}
          </span>
          <span>
            <b>USD/MXN</b>: {trms.usd_mxn && trms.usd_mxn.toFixed(2)}
          </span>
          <span>
            <b>EUR/MXN</b>: {trms.eur_mxn && trms.eur_mxn.toFixed(2)}
          </span>
          <span>
            <b>EUR/COP</b>: {trms.eur_cop && trms.eur_cop.toFixed(2)}
          </span>
          <span>
            <b>USD/EUR</b>: {trms.usd_eur && trms.usd_eur.toFixed(2)}
          </span>
          <span> </span>
        </div>
      </Marquee>}
      <div className="d-flex align-items-center">
        <div style={{ marginRight: "20px", display: "flex", gap: "5px" }}>
          <Button
            disabled={i18n.language === "es"}
            handler={() => {
              i18n.changeLanguage("es");
            }}
            size={"xs"}
            text={"Es"}
            fontSize="12px"
            endIcon={{ name: "Language", size: "10" }}
            textColor={"primary_300"}
            bgColor={"neutral_100"}
          />
          <Button
            disabled={i18n.language === "en_US"}
            handler={() => {
              i18n.changeLanguage("en_US");
            }}
            fontSize="12px"
            size={"xs"}
            text={"En "}
            endIcon={{ name: "Language", size: "10" }}
            textColor={"primary_300"}
            bgColor={"neutral_100"}
          />
        </div>
        
        <div className="profile">
        <DropdownAvatar user={user}/>
          {/* <Avatar
            text={`${user?.name[0]?.toUpperCase()}${user?.lastName[0]?.toUpperCase()}`}
            size="md"
          ></Avatar>
          <Link
            to="/profile/?tab=information"
            style={{
              textDecoration: "none",
            }}
          >
            {user.name + " " + user.lastName}
          </Link> */}
        </div>
      </div>
    </NavContainer>
  );
}

export default Navbar;
