import React, { useEffect, useState } from "react";
import { styled, Button as Btn } from "@nextui-org/react";
import theme from "../../theme";

// Componets
import Icon from "../Icon/Icon";

function Button({
  text,
  textColor,
  bgColor,
  hoverColor,
  outline,
  handler,
  size,
  startIcon,
  endIcon,
  disabled,
  rounded,
  bold,
  shadow,
  width,
  type,
  padding,
  fontSize = "14px",
  animated = true
}) {
  const [iconColor, setIconColor] = useState(startIcon?.color || endIcon?.color)

  const StyledBtn = styled(Btn, {
    [`.${theme} &`]: {
      backgroundColor: `$${bgColor || "primary_50"}`,
      color: `$${textColor || "primary_400"}`,
      opacity: `${disabled ? '0.5' : ''}`,
      minWidth: "fit-content",
      width:width || "fit-content",
      display: 'inline-block',
      fontWeight: `${bold ? '600' : '400'}`,
      fontSize:fontSize,
      boxShadow: `${shadow ? '0 2px 8px 2px rgb(104 112 118 / 0.07)' : ''}`,
      padding: `${padding !== undefined ? `0rem` : undefined}`,
      [".nextui-button-text"]: {
        gap: "0.4rem",
        height: "100%"
      },
      ['&:hover']: {
        backgroundColor: `$${hoverColor ? hoverColor.bgColor : ''}`,
        color: `$${hoverColor ? hoverColor.textColor : ''}`
      }
    },
  });

  const StyledBtnOutline = styled(Btn, {
    [`.${theme} &`]: {
      backgroundColor: `$${bgColor || "primary_50"}`,
      border: `solid $${textColor || "primary_400"} 1px`,
      color: `$${textColor || "primary_400"}`,
      opacity: `${disabled ? '0.5' : ''}`,
      width: `${width ? width : 'fit-content'}`,
      minWidth: "fit-content",
      display: 'inline-block',
      fontWeight: `${bold ? '600' : '400'}`,
      [".nextui-button-text"]: {
        gap: "0.4rem",
        height: "100%"
      },
      ['&:hover']: {
        backgroundColor: `$${hoverColor ? hoverColor.bgColor : ''}`,
        color: `$${hoverColor ? hoverColor.textColor : ''}`
      },
    }
  });

  const handlerClick = () => {
    console.log("pressed")
    try {
      handler();
      onButtonClick && onButtonClick();
    } catch (error) { }
  };

  if (outline)
    return (
      <div
        onMouseEnter={() => hoverColor ? setIconColor(hoverColor.textColor) : ''}
        onMouseLeave={() => hoverColor ? setIconColor(startIcon.color) : ''}
      >
        <StyledBtnOutline
          onPress={handlerClick}
          size={size || "md"}
          className="theme"
          disabled={disabled}
          rounded={rounded}
          animated={animated}
          type={type}
        >
          {startIcon !== undefined ? (
            <Icon
              name={startIcon.name || undefined}
              color={disabled ? '#DFE2E6' : iconColor || undefined}
              size={startIcon.size || undefined}
            ></Icon>
          ) : (
            ""
          )}
          {text}
          {endIcon !== undefined ? (
            <Icon
              name={endIcon.name || undefined}
              color={disabled ? '#DFE2E6' : iconColor || undefined}
              size={endIcon.size || undefined}
            ></Icon>
          ) : (
            ""
          )}
        </StyledBtnOutline>
      </div>
    );

  return (
    <div
      onMouseEnter={() => hoverColor ? setIconColor(hoverColor.textColor) : ''}
      onMouseLeave={() => hoverColor ? setIconColor(startIcon.color) : ''}
    >
      <StyledBtn
        onPress={handlerClick}
        size={size || "md"}
        className="theme"
        disabled={disabled}
        rounded={rounded}
        animated={animated}
        type={type}
      >
        {
          startIcon !== undefined ? (
            <Icon
              name={startIcon.name || undefined}
              color={disabled ? '#DFE2E6' : iconColor || undefined}
              size={startIcon.size || undefined}
            ></Icon>
          ) : (
            ""
          )
        }
        {text}
        {endIcon !== undefined ? (
          <Icon
            name={endIcon.name || undefined}
            color={disabled ? '#DFE2E6' : iconColor || undefined}
            size={endIcon.size || undefined}
          ></Icon>
        ) : (
          ""
        )}
      </StyledBtn>
    </div>
  );
}

export default Button;
