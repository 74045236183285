import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Loading } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import {getPurchaseOrders, getServiceSplit } from "../../../services/quotation.services";

// Components
import Table from "../../../components/Table/Table";

import { useTranslation } from "react-i18next";
import Pagination from "../../../components/Pagination/Pagination";
import { ROLES } from "../../../constants";
import { getCountriesNew } from "../../../services/countries.services";
import SubscriptionModal from "./SubscriptionModal";
import { getQuoteData } from "../../../services/quotations.services";
import { getPreliquidations } from "../../../services/preliquidation.services";
import { getPreliquidationCosts } from "../../functions";
import NewTable from "../../../components/Table/Table";

const TableServices = ({ filterType, filterSearch, loading, setLoading }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const token = user.token;
  const { t: translate } = useTranslation("Services");

  const [open, setOpen] = useState(false)
  const [currentService, setCurrentService] = useState()

  let columns = [
    { name:translate("actions"), uid: "actions", type: "action" },
    { name:translate("radication_id"), uid: "idRadication", type: "text", style: { width: "auto" } },
    { name:translate("po"), uid: "purchaseOrder", type: "text" },
    { name:translate("load"), uid: "load", type: "text" },
    { name:translate("date"), uid: "date", type: "date" },
    // { name:translate("hour"), uid: "hour", type: "text" },
    { name:translate("origin"), uid: "origin", type: "country" },
    { name:translate("method"), uid: "method", type: "icon" },
    { name:translate("destination"), uid: "destination", type: "country" },
    { name: "Costo", uid: "cost", type: "text" },
    { name:translate("advisor"), uid: "advisor", type: "text" },
  ];
  
  let isClient = false;
  user.roles.forEach((role) => {
    if ([ROLES.User || ROLES.Client].includes(role)) {
      isClient = true;
    }
  });

  if (isClient) {
    columns = [
      { name: "Acciones", uid: "actions", type: "action" },
      { name: "Referencia", uid: "ref", type: "text", style: { width: "auto" }},
      { name: "Carga", uid: "load", type: "text" },
      { name: "Fecha", uid: "date", type: "date" },
      { name: "Origen", uid: "origin", type: "country" },
      { name: "Destino", uid: "destination", type: "country" },
      { name: "Costo", uid: "cost", type: "text" },
      { name: "Asesor", uid: "advisor", type: "text" },
    ];
  }

  const actions = [
    {
      name: "Ver detalle",
      icon: "View",
      handle: (item) => navigate(`/services/detail/chat/?radication=${item.idRadication}&tab=chat`),
    },
    {
      name: 'Rastreo',
      icon: 'Tracking',
      handle: (item) => navigate(`/tracking/service/?radication=${item.idRadication}&tab=chat`)
    },
    {
      name: "Subscribir",
      icon: "Mail",
      handle: (item) => {setCurrentService(item), setOpen(true)},
    },
  ];

  async function getData(currentPage, pageSize, filterSearch) {
    try {
      const asyncTasks = [
        getServiceSplit(token, currentPage, pageSize, filterSearch),
        getPurchaseOrders(token),
        getCountriesNew(token),
        getQuoteData(token),
        getPreliquidations(token),
      ];
      const res = await Promise.all(asyncTasks);
  
      const servicesData = await res[0].json();
      const purchaseOrdersData = await res[1].json();
      const countriesData = await res[2].json();
      const liquidationData = await res[3].json();
      const preliquidationData = await res[4].json();
  
      const filteredServices = servicesData?.results;
      const tableData = filteredServices?.map((service) => {
        const purchaseOrder = purchaseOrdersData.find((po) => po.service === service.id)?.purchaseNumber;
        const methodId = service?.shipping?.shipping_method;
        
        const method = methodId === 1 ? {name: "Aereo", text: "Aereo"} :
          methodId === 2 ? {name: "Maritimo", text: "Maritimo"} :
          methodId === 3 ? {name: "Terrestre", text: "Terrestre"} :
          methodId === 4 ? {name: "Bbk", text: "Bbk"} :
          methodId === 5 ? {name: "Roro", text: "Roro"} :
          methodId === 6 ? {name: "Containers", text: "Contenedores especiales"} :
          {name: "Close", text: "No especificado"};
  
        const originCountry = countriesData.find(country => country.name.toLowerCase() === service?.shipping?.origin_boarding_terminal?.city?.country?.name.toLowerCase());
        const destinationCountry = countriesData.find(country => country.name.toLowerCase() === service?.shipping?.destination_boarding_terminal?.city?.country?.name.toLowerCase());
  
        const approvedLiquidations = liquidationData.filter(liquidation => liquidation.customer_approval);
        const serviceLiquidation = approvedLiquidations.find(liquidation => liquidation.radication === service.radication);
        
        let cost = 0;
        if (serviceLiquidation?.preliquidation_ids) {
          const prelId = serviceLiquidation.preliquidation_ids[0];
          const prel = preliquidationData.find(prel => prel.id === prelId);
          const { totalCost } = getPreliquidationCosts(prel);
          cost = totalCost;
        }   
  
        return { 
          ...service,
          originCountry,
          method,
          destinationCountry,
          purchaseOrder,
          cost,
        };
      });
  
      const dataTable = tableData.map((service) => {
        const user = service.users.find((user) => user.user?.groups?.includes("Asesores"))?.user ||
          service.users.find((user) => user.user?.groups?.includes("Pricing")) ?.user ||
          service.users.find((user) => user.user?.groups?.includes("Operations"))?.user;

        const purchaseOrder = service.purchaseOrder;
        return {
          id: service.id,
          purchaseOrder,
          ref: purchaseOrder && purchaseOrder !== "Ninguna" ? purchaseOrder : service.radication,
          idRadication: service.radication,
          load: service.loads.length > 0 ? service.loads[0].load_type.name : "",
          date: new Date(service.created_at),
          method: service.method,
          origin: service.originCountry,
          destination: service.destinationCountry,
          advisor: `${user?.first_name || ""} ${user?.last_name || ""}`,
          cost: service.cost ? service.cost?.toFixed(2) + ' USD' : '',
        };
      });
      console.log("dataTable :",dataTable)
      return { data: dataTable, count: servicesData?.count };
    } catch (error) {
      console.error("Error fetching data", error);
      throw error;
    }
  }

  return (
    <div className="h-100">
      <SubscriptionModal open={open} setOpen={setOpen} service={currentService}/> 
      <Table
        columns={columns}
        actions={actions}
        serverPagination={true}
        searchBar={false}
        getData={getData}
        filterSearch={filterSearch}
      />
    </div>
  );
};

export default TableServices;
