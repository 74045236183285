import React, { useEffect, useRef, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import Icon from '../Icon/Icon';
import ReactCountryFlag from 'react-country-flag';
import { Dropdown, Loading, Tooltip, styled } from '@nextui-org/react';
import useDraggableScroll from 'use-draggable-scroll';
import Button from '../Button/Button';
import Input from '../Input/Input';
import { debounce } from 'lodash';
import * as XLSX from "xlsx";
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import ReactStars from 'react-stars';

const StyledActions = styled("div", {
    backgroundColor: "$primary_50",
    color: "$primary_400",
    minWidth: "fit-content",
    width: "50%",
    position: "unset",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    padding: "0.5rem",
    borderRadius: "30px",
    [".nextui-button-text"]: {
    gap: "0.4rem",
    },
});

const Container = styled("div",{
    fontSize:'10px',
    width: '100%',
    background:"white",
    borderRadius:'8px',
    boxShadow:'$elevation_1',
});
const ExcelButton = styled("button", {
    display: "flex",
    background: "white",
    color: "$excel",
    gap: "5px",
    borderRadius: "8px",
    padding: "10px",
    transition: "300ms",
    ["&:hover"]: {
      cursor: "pointer",
      background: "$neutral_100",
    },
  });
const Draggable = styled("div", {
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      height: "13px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#5E6EAB",
      borderRadius: "50px",
    },
  });

export default function Table({columns, items, actions, serverPagination=false, getData, filterSearch, hidePagination=false, searchBar=true, excel=true}) {

    const ref = useRef("div");
    const { t: translate } = useTranslation("Components");
    const { onMouseDown } = useDraggableScroll(ref, { direction: "horizontal" });

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
    });

    const [rows, setRows] = useState(items || []);
    const [displayItems, setDisplayItems] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [loading, setLoading] = useState(false);

    const handlerAction = (key, item) => {
        actions[key].handle(item);
    };
    useEffect(()=>{
        setRows(items)
    },[items])
    const formattedColumns = columns.map(col => {
        switch(col.type){
            case "country": return {
                field: col.uid, 
                headerName: col.name, 
                flex: 1, 
                renderCell: (params => (
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <Tooltip content={params.formattedValue?.name} color="primary">
                            <ReactCountryFlag
                                countryCode={params.formattedValue?.code}
                                svg
                                style={{ width: "2em", height: "1.5em", margin:'14px', borderRadius: "4px" }}
                            />
                        </Tooltip>
                    </div>
                ))
            };
            case "rate": return {
                field:col.uid,
                headerName: col.name,
                flex:1,
                renderCell:(params => (
                    <ReactStars
                        value={3}
                        count={5}
                        isHalf={true} // Permite medias estrellas
                        edit={true} // Desactiva la interacción
                        size={15}
                        activeColor="#ffd7d0"
                        onChange={(e)=>{console.log("e :",e)}}
                    />
                ))
            }
            case "icon": return {
                field: col.uid, 
                headerName: col.name, 
                flex: 1, 
                renderCell: (params => (
                    <div style={{display: "flex", justifyContent: "center", marginTop:'13px', overflowX:"auto"}}>
                        <Tooltip content={params.formattedValue?.text} color="primary">
                            <Icon
                                name={params.formattedValue?.name}
                                color={params.formattedValue?.color}
                                size="20px"
                            />
                        </Tooltip>
                    </div>
                ))
            };
            case "action": return {
                field: col.uid, 
                headerName: col.name, 
                flex: 1, 
                renderCell: (params => (
                    <div style={{display:'flex', alignItems:'center', marginTop:'10px'}}>
                        <Dropdown placement="bottom-left">
                            <Dropdown.Trigger>
                                <StyledActions className="theme">
                                    <Icon name="MenuVertical" size="14"></Icon>
                                </StyledActions>
                            </Dropdown.Trigger>
                            <Dropdown.Menu
                                onAction={(key) => handlerAction(key, params.row)}
                                aria-label="Table actions"
                                css={{ minWidth: "100px" }}
                            >
                            {actions.map((element, i) => (
                                <Dropdown.Item
                                    key={i}
                                    onClick={() => element.handle(params.row)}
                                    icon={<Icon name={element.icon}></Icon>}
                                >
                                {element.name}
                                </Dropdown.Item>
                            ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div> 
                ))
            };
            case "date": return {
                field: col.uid, 
                headerName: col.name, 
                flex: 1,
                renderCell: (params => {
                    const date = new Date(params.value);
                    return format(date,"dd/MM/yyyy HH:MM");
                })
            };
            case "textCenter": return {
                field: col.uid, 
                headerName: col.name, 
                flex: 1,
                renderCell: (params => {
                    return <p style={{ width: "fit-content", margin:"auto",marginTop:'15px', fontSize: "10px" }}>{params.value}</p>
                })
            };
            case "button": return {
                field: col.uid, 
                headerName: col.name, 
                // flex: 1,
                renderCell: (params => {
                    return <Button
                    size="sm"
                    fontSize='10px'
                    text={params.value?.text}
                    handler={params.value?.handler}
                    disabled={params.value?.disabled}
                    bgColor="primary_400"
                    textColor="neutral_50"
                  />
                })
            };
            case "custom": return {
                field: col.uid, 
                headerName: col.name, 
                flex: 1,
                renderCell: (params => {
                    return params.value
                })
            };
            default: return {
                field: col.uid, 
                headerName: col.name, 
                flex: 1
            };
        }
    });
    
    useEffect(() => {
        if (serverPagination){
            setLoading(true)
            getData(paginationModel.page + 1, paginationModel.pageSize, filterSearch).then(async res => {
                const asyncTableData = res
                setRows(asyncTableData.data);
                setRowCount(asyncTableData.count);
                setLoading(false)
            }).catch(() => setLoading(false));
        }
    }, [paginationModel, filterSearch]);

    useEffect(() => {
        if (filterSearch){
            setLoading(true)
            getData(1, 30, filterSearch).then(async res => {
                const asyncTableData = res
                setRows(asyncTableData.data);
                setRowCount(asyncTableData.count);
                setLoading(false)
            }).catch(() => setLoading(false));
        }
    }, [filterSearch]);

    useEffect(()=>{
        setDisplayItems(rows)
    },[rows])

    function handleGeneralSearch(input) {
        const filtered = items?.filter((item) => {
          for (let key in item) {
            if (item[key]?.toString().toLowerCase().includes(input.toLowerCase())) {
              return true;
            }
          }
          return false;
        });
        setDisplayItems(filtered);
    }
    const debouncedSearch = debounce((input) => {
        handleGeneralSearch(input);
    }, 300);

    const getCurrentDate = () => {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0");
        const yyyy = today.getFullYear();
        return dd + mm + yyyy;
      };

    const exportToExcel = (data) => {
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
    
        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
    
        const blob = new Blob([excelBuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const currentDate = getCurrentDate();
        const fileName = `VALexport-${currentDate}.xlsx`;
        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.download = fileName;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      };

    return (
        loading ? <Loading/> :<Container>
            {/* <Draggable ref={ref} onMouseDown={onMouseDown}> */}
            <div style={{position:'relative', margin:'20px 0', paddingTop:'10px'}}>
            <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "15px",
        }}
      >
        {searchBar && (
          <Input
            name="table searchbar"
            placeholder={"buscar..."}
            onChange={(e) => debouncedSearch(e.target.value)}
          />
        )}
        {excel && (
          <ExcelButton
            onClick={() => {
              exportToExcel(displayItems);
            }}
          >
            {" "}
            {translate("export_excel")} <Icon name="Excel" size="20" />
          </ExcelButton>
        )}
        </div>
                <DataGrid
                    sx={{ background: "white", fontSize:'10px' }}
                    rows={displayItems? displayItems : rows}
                    columns={formattedColumns.map(col => ({...col, headerAlign: 'center', minWidth : 70}))}
                    initialState={{ density: 'compact' }}
                    pageSizeOptions={[40]}
                    pageSize={40}
                    rowHeight={70}
                    pagination={undefined}
                    paginationMode={serverPagination ? 'server' : 'client'}
                    rowCount={serverPagination ? rowCount : rows?.length}
                    paginationModel={paginationModel}
                    onPaginationModelChange={(e)=>{setPaginationModel(e)}}
                />
                {hidePagination && <div style={{position:'absolute', width:'90%', height:'30px', background:'white', bottom:10, right:10}}/>}
            </div>
            
            {/* </Draggable> */}
        </Container>
    )
}
