import React, { useEffect, useState } from "react";
import { getMovements } from "../../../services/quotations.services";
import { Loading, Tooltip, styled } from "@nextui-org/react";
import { useSelector } from "react-redux";
import Table from "../../../components/Table/Table";
import { getUsers } from "../../../services/user.services";
import { format } from "date-fns/esm";
import { getCountriesNew } from "../../../services/countries.services";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "../../../components/Button/Button";
import toast from "../../../components/Toast/ToastTypes";
import { getEmissions, recalculateCO2 } from "../../../services/CO2.services";

export default function MovementsTable() {
  const token = useSelector((state) => state.user.token);
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState({});
  const { t: translate } = useTranslation("Admin");

  const actions = [
    {
      name: "Ir a servicio",
      icon: "Servicios",
      handle: (item) => {
        if (item.customer_approval?.state || item.operation?.state) {
          navigate(
            `/services/detail/chat/?radication=${item.radication}&tab=chat`
          );
        } else {
          toast("warn", "La cotización aún no tiene servicio");
        }
      },
    },
    {
      name: "Ir a preliquidación",
      icon: "Cotizaciones",
      handle: (item) => {
        console.log("item :", item);
        if (item.preliquidations?.state) {
          navigate(
            `/manageQuotes/?tab=pre-liquidation&selectId=${item.quotation?.id}`
          );
        } else {
          toast("warn", "La cotización aún no tiene preliquidación");
        }
      },
    },
    {
      name: "Ir a operación",
      icon: "Operations",
      handle: (item) => {
        if (item.operation?.state) {
          navigate(
            `/operations/tracking/?tab=events&selectId=${item.operation}`
          );
        } else {
          toast("warn", "Aún no tiene operación");
        }
      },
    },
    {
      name: "Ir a rastreo",
      icon: "Tracking",
      handle: (item) => {
        if (item.tracking?.state) {
          navigate(`/tracking/service/?radication=${item.radication}&tab=chat`);
        } else {
          toast("warn", "La cotización aún no tiene rastreo");
        }
      },
    },
  ];

  const columns = [
    { name: translate("actions"), uid: "actions", type: "action" },
    { name: translate("radication"), uid: "radication", type: "text" },
    { name: translate("po"), uid: "po", type: "text" },
    { name: translate("client"), uid: "client", type: "text" },
    { name: translate("origin"), uid: "origin", type: "country" },
    { name: translate("method"), uid: "method", type: "icon" },
    { name: translate("destination"), uid: "destination", type: "country" },
    { name: translate("created_at"), uid: "created_at", type: "text" },
    { name: translate("preliquidations"), uid: "preliquidations", type: "icon"},
    { name: translate("liquidation_filled"), uid: "approve", type: "icon" },
    { name: translate("customer_approval"), uid: "customer_approval", type: "icon",},
    { name: translate("started_operation"), uid: "operation", type: "icon" },
    { name: translate("has_tracking"), uid: "tracking", type: "icon" },
    { name: translate("co2"), uid: "co2", type: "icon" },
    { name: translate("documents"), uid: "documents", type: "custom" },
  ];

  function getData() {
    const asyncTasks = [ getMovements(token), getUsers(token), getCountriesNew(token)];
    setLoading(true);
    Promise.all(asyncTasks).then(async (res) => {
        const movementsData = await res[0].json();
        const usersData = await res[1].json();
        const countriesData = await res[2].json();

        const tableData = movementsData.map((item) => {
          const client = usersData.find(
            (user) => user.id === item.quotation?.client
          );
          const originArray = item.quotation?.shipping?.origin_boarding_terminal.split(",");
          const destinationArray = item.quotation?.shipping?.destination_boarding_terminal.split(",");
          const originCountryName = originArray ? originArray[originArray.length - 1]?.toLowerCase()?.trim(): "";
          const destinationCountryName = destinationArray? destinationArray[destinationArray.length - 1]?.toLowerCase()?.trim(): "";
          const origin = countriesData.find((country) => country.name.toLowerCase() === originCountryName?.toLowerCase());
          const destination = countriesData.find((country) => country.name.toLowerCase() === destinationCountryName?.toLowerCase());
          const prelIds = item.quotation?.preliquidation_ids;

          return {
            id: item.id,
            radication: item.radication,
            origin,
            destination,
            quotation: item.quotation,
            po: item?.purchase_order?.purchaseNumber || "X",
            client: `${client?.name} ${client?.last_name}`,
            created_at: item.quotation?.created_at? format( new Date(item.quotation?.created_at), "dd/MM/yyyy , HH:mm" ) : "",
            preliquidations: prelIds?.length ? {
                name: "CheckSquare",
                state: true,
                color: "green",
                text: (
                  <div>
                    <p>La liquidacion ya tiene preliquidacion :</p>
                    <Link
                      to={`/manageQuotes/?tab=pre-liquidation&selectId=${item.quotation.id}`}
                    >
                      Ir a cotización{" "}
                    </Link>
                  </div>
                ),
                exportValue: item.quotation.id,
              } : {
                name: "Close",
                color: "grey",
                state: false,
                text: "Aun no tiene preliquidaciones",
                exportValue: "No",
              },
              approve: item.quotation?.approve ? {
                name: "CheckSquare",
                color: "green",
                text: "Liquidacion enviada a cliente",
                exportValue: item.quotation.approve,
              } : {
                name: "Close",
                color: "grey",
                text: "Liquidacion en proceso",
                exportValue: "No",
              },
              customer_approval: item.quotation?.customer_approval ? {
                name: "CheckSquare",
                state: true,
                color: "green",
                text: "Liquidacion aprobada por el cliente",
                exportValue: item.quotation.customer_approval,
              } : {
                name: "Close",
                state: false,
                color: "grey",
                text: "Esperando aprobacion del cliente",
                exportValue: "No",
              },
            operation: item?.operation ? {
                  name: "CheckSquare",
                  state: true,
                  color: "green",
                  text: (
                    <div>
                      <p>La cotización ya tiene una operacion asociada</p>
                      <Link
                        to={`/operations/tracking/?tab=events&selectId=${item.operation}`}
                      >
                        {" "}
                        Ir a operacion{" "}
                      </Link>
                    </div>
                  ),
                  exportValue: item.operation,
                } : {
                  name: "Close",
                  state: false,
                  color: "grey",
                  text: "Aun no tiene operacion",
                  exportValue: "No",
                },
            tracking: item.tracking ? {
                  name: "CheckSquare",
                  state: true,
                  color: "green",
                  text: (
                    <div>
                      <p>La liquidacion ya tiene rastreo :</p>
                      <Link to={`/tracking/service/?radication=${item.radication}&tab=chat`}>
                        Ir a rastreo{" "}
                      </Link>
                    </div>
                  ),
                  exportValue: item.tracking,
                } : {
                  name: "Close",
                  color: "grey",
                  state: false,
                  text: "Aun no tiene rastreo",
                  exportValue: "No",
                },
                co2: item?.calculation ? {
                  name: "CheckSquare",
                  color: "green",
                  text: (
                    <div>
                      <p>
                        CO₂ emitido : {item.calculation.weight}{" "}
                        {item.calculation.weight_unit}
                      </p>
                      <p>Especificacion : {item.calculation.specification}</p>
                    </div>
                  ),
                  exportValue: `${item.calculation.weight} ${item.calculation.weight_unit}`,
                } : {
                  name: "Close",
                  color: "grey",
                  text: (
                    <div>
                      <p style={{ margin: "10px 0" }}>
                        No tiene calculos de co2
                      </p>
                      <Button
                        text="Calcular"
                        width="100%"
                        handler={() => handleRecalculate(item)}
                      />
                    </div>
                  ),
                  exportValue: "No",
                },
            documents: (
              <div>
                <Tooltip
                  color={"primary"}
                  style={{minWidth:'500px'}}
                  placement="leftStart"
                  content={
                    <div style={{ display: "flex", flexDirection: "column", minWidth:'500px' }}>
                      {item?.service_documents?.slice(0, 5)?.map((e, i) => (
                        <p key={i}>
                          {i + 1} - {e.name}
                        </p>
                      )) || "Ninguno"}
                      <Link to={`/services/detail/chat/?radication=${item?.quotation?.radication}&tab=attachments`}>
                        Ir a documentos
                      </Link>
                    </div>
                  }
                >
                  {item?.service_documents?.length}
                </Tooltip>
              </div>
            ),
          };
        });
        setItems(tableData);
        setLoading(false);
      }).catch(() => toast("error", "No se pudieron cargar los datos"));
  }

  const handleRecalculate = async (item) => {
    try {
      await recalculateCO2(token, item.radication);
      const res = await getEmissions(token, item.radication);
      const emissionsData = await res.json();
      const distanceInMiles = emissionsData?.distance;
      const distanceInKilometers = distanceInMiles * 1.60934;
      console.log("emissionsData :", emissionsData);
      toast(
        "success",
        <div>
          <p>Cálculo hecho exitosamente</p>
        </div>
      );
      getData();
    } catch (error) {
      console.error("Error recalculating emissions:", error);
      toast("error", "Hubo un error calculando el co2");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "lightgrey",
            height: "60vh",
            borderRadius: "8px",
          }}
        >
          <Loading size="xl" />
        </div>
      ) : (
        <Table columns={columns} items={items} actions={actions} />
      )}
    </div>
  );
}
