import React from 'react';
import PropTypes from 'prop-types';
import {
  Bar,
  Line,
  YAxis,
  XAxis,
  Tooltip,
  CartesianGrid,
  ComposedChart,
  ResponsiveContainer,
  Legend,
} from 'recharts';

const UltraHorizontalBarChart = ({ data, barDataKeys, lineDataKeys }) => {

  return (
    <ResponsiveContainer width="100%" height="90%" minHeight='300px'>
      <ComposedChart
        data={data}
        margin={{
          left: 0,
        }}
        barSize={28}
        className="[&_.recharts-tooltip-cursor]:fill-opacity-20 dark:[&_.recharts-tooltip-cursor]:fill-opacity-10 [&_.recharts-cartesian-axis-tick-value]:fill-gray-500 [&_.recharts-cartesian-axis.yAxis]:-translate-y-3 rtl:[&_.recharts-cartesian-axis.yAxis]:-translate-x-12"
      >
        <CartesianGrid vertical={false} strokeOpacity={0.435} strokeDasharray="8 10" />
        <XAxis 
          dataKey="label" 
          axisLine={false} 
          tickLine={false}
          interval={0}
          tick={{ width: 40, height: 60, wordWrap: 'break-word', fontSize: 11 }}
        />
        <YAxis axisLine={false} tickLine={false} />
        <Tooltip />
        {barDataKeys.map(({ key, color }) => (
          <Bar key={key} dataKey={key} fill={color} radius={4} />
        ))}
        {lineDataKeys.map(({ key, color }) => (
          <Line
            key={key}
            type="monotone"
            dataKey={key}
            stroke={color}
            fill={color}
            strokeWidth={2}
            dot={false}
          />
        ))}
        {/* <Legend /> */}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default UltraHorizontalBarChart;
