import React, { Children } from 'react';
import { styled } from "@nextui-org/react";
import theme from "../../theme";

function PageTitle({ children, showIcon = true }) {
    const StyledTitle = styled("div", {
        [`.${theme} &`]: {
            color: "$primary_500",
            display: "flex",
            alignItems: "center",
            gap: 8,
            h3: {
                margin: 0
            },
            "@media (max-width: 400px)":{
                h3:{
                    fontSize:'16px',
                }
            }
        }
    })
    const StyledTag = styled("div", {
        [`.${theme} &`]: {
            background: "$secondary_200",
            width: 15,
            height: 26,
            borderRadius: "2rem"
        }
    })
    return (
        <StyledTitle>
            {showIcon ? <StyledTag /> : null}
            <h3>
                {children}
            </h3>
        </StyledTitle>
    );
}

export default PageTitle;