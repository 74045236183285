import React from 'react';
import { PieChart, Pie, Legend, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const NoDataMessage = () => (
  <div style={{ textAlign: 'center', padding: '10px', color: '#777' }}>
    <FontAwesomeIcon icon={faExclamationCircle} style={{ fontSize: '1.2em', marginRight: '5px' }} />
    <p style={{ display: 'inline-block', verticalAlign: 'middle', fontSize: '0.8em', fontStyle: 'italic' }}>Sin datos disponibles</p>
  </div>
);

const ColoredPieChart = ({ data, dataKey, colors, radius = 100, legend = false, tooltip = true }) => {
  if (!data || data.length === 0) {
    return <NoDataMessage />;
  }
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${index} ${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <ResponsiveContainer width="100%" height="83%" minHeight='300px'>
      <PieChart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          outerRadius={radius}
          innerRadius={30}
          label
          fill="#8884d8"
          dataKey="value"
          isAnimationActive={true}
          strokeWidth={2}
          paddingAngle={8}
          cornerRadius={6}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        {tooltip && <Tooltip />}
        {legend && <Legend iconSize={10} layout='horizontal' iconType='circle'/>}
      </PieChart>
    </ResponsiveContainer>
  );
};

export default ColoredPieChart;
