import React, { useEffect, useState } from "react";
import { Loading, Tooltip, styled } from "@nextui-org/react";
import SmallField from "./Fields/SmallField";
import SmallSelect from "./Fields/SmallSelect";
import {getLoadTypes, getMoveTypes, getProviders, uploadPreliquidationExcel} from "../../../../services/preliquidation.services";
import { getBusinessTerms } from "../../../../services/businessTerms.services";
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import PackagingList from "./PackagingList";
import { getTrms } from "../../../../services/kpis.services";
import { getAirports, getAirportsByCountry, getCountriesNew, getPorts, getPortsByCode, getPortsByCountry } from "../../../../services/countries.services";
import Icon from "../../../../components/Icon/Icon";
import { URL } from "../../../../constants";
import InputFileCard from "../../../../components/InputFileCard/InputFileCard";
import { netDefaultData, servicesDefaultData } from "./constants";
import WarningModal from "./WarningModal";
import { format, parseISO } from "date-fns";

const Container = styled("div", {
  margin: "20px",
  display: "grid",
  gap: "20px 60px",
  gridTemplateColumns: "repeat(2, 1fr)",
  [".subtitle"]: {
    gridColumn: "span 2",
    borderBottom: "3px solid $primary_300",
  },
  ["field"]: {
    display: "flex",
  },
  h3: {
    color: "#3D509A",
  },
});

export default function Shipment({ trigger, rfqData }) {
  const token = useSelector((state) => state.user.token);

  const [airlines, setAirlines] = useState([]);
  const [open, setOpen] = useState(false);
  const [incoterms, setIncoterms] = useState([]);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [loadTypeOptions, setLoadTypeOptions] = useState([]);
  const [moveTypeOptions, setMoveTypeOptions] = useState([]);
  const [originBoardingTerminals, setOriginBoardingTerminals] = useState([]);
  const [destinationBoardingTerminals, setDestinationBoardingTerminals] = useState([]);
  const [allTerminals, setAllTerminals] = useState([]);
  const [providers, setProviders] = useState(true);
  const [disableUN, setDisableUN] = useState(true);
  const [loadingExcel, setLoadingExcel] = useState(false)
  const methods = useFormContext();
  const { getValues, setValue, reset } = useFormContext();

  const actualWeight = methods.getValues('shipping_information.weight') || 0
    const actualVolume = methods.getValues('shipping_information.volume') || 0
    const chargeableVol = methods.getValues('shipping_information.chargeable_vol') || 0
    const TotalGW = methods.getValues('total_weight_kg') || 0
    const pieces = methods.getValues('shipping_information.pieces') || 0
    const valueOfCargo = methods.getValues('shipping_information.cargo_value') || 0
    const freight = methods.getValues('air_freight.freight_total') || 0
    const freightTotal = methods.getValues('air_freight.total') || 0
    const originTotal = methods.getValues('origin_listTotal') || 0
    const destinationTotal = methods.getValues('destination_list_total') || 0
    const insuranceTotal = methods.getValues('insurance_list_total') || 0
    
    const unitOptions = [
        {label:'At cost', value:1},
        {label:'HBL', value:1},
        {label:'HAWB', value:1},
        {label:'shpt', value:1},
        {label:'ton', value: actualWeight/1000},
        {label:'cbm', value: actualVolume},
        {label:'W/M', value: Math.max(actualVolume/1000000, actualWeight/1000)},
        {label:'KG/Vol', value: Math.max(actualVolume/1000000, actualWeight/1000)},
        {label:'Kg', value: TotalGW},
        {label:'Chargable Weight', value: Math.max(actualWeight, chargeableVol)},
        {label:'lb', value: actualWeight/0.45},
        {label:'Pallet', value: pieces},
        {label:'Piece', value: pieces},
        {label:'Box', value:1},
        {label:'Unit', value:1},
        {label:'Cntr', value:1},
        {label:'Truck', value:1},
        {label:'Forklift', value:1},
        {label:'Crane', value:1},
        {label:'Equipment', value:1},
        {label:"Cntr 20'", value:1},
        {label:"Cntr 40'", value:1},
        {label:"Cntr 20' OT", value:1},
        {label:"Cntr 40' OT", value:1},
        {label:"Cntr 20' FR", value:1},
        {label:"Cntr 40' FR", value:1},
        {label:'TEU', value:1},
        {label:'Day', value:1},
        {label:'Hrs', value:1},
        {label:'Day x Cntr', value:1},
        {label:'Document', value:1},
        {label:'Certificado', value:1},
        {label:'UN', value:1},
        {label:'Cargo Value', value: valueOfCargo},
        {label:'FOB Value', value: valueOfCargo},
        {label:'CFR Value', value: Number(valueOfCargo) + Number(originTotal) + Number(freightTotal)},
        {label:'CIF Value', value: Number(valueOfCargo) + Number(originTotal) + Number(freightTotal) + Number(insuranceTotal)},
        {label:'Freight', value: freight},
        {label:'Freight+Fuel', value: freightTotal},
        {label:'Total Freight+Origin Charges', value: Number(freightTotal) + Number(originTotal)},
        {label:'Destination Charges', value: destinationTotal},
        {label:'Inspection', value:1},
        {label:'HS CODE', value:1},
        {label:'Duty and tax outlays', value:1},
        {label:'Service', value:1},
        {label:'Tramite', value:1},
        {label:'Per Day / Pro rata', value:1},
        {label:'Short Ton', value: Math.max(actualWeight, chargeableVol)/900},
        {label:'2000/lb', value:Math.max(actualWeight, chargeableVol)/900},
    ]

  useEffect(() => {
    const shippingMethod = getValues("shipping_method");
    const asyncTasks = [
      getProviders(token),
      getBusinessTerms(token),
      getTrms(token),
      getLoadTypes(token),
      getMoveTypes(token),
    ];
    if (shippingMethod === "air") {
      asyncTasks.push(
        getAirportsByCountry(token, rfqData?.shipping?.origin_boarding_terminal.city.country.name),
        getAirportsByCountry(token, rfqData?.shipping?.destination_boarding_terminal.city.country.name)
      );
      asyncTasks.push(getAirports(token));
    } else {
      asyncTasks.push(
        getPortsByCode(token, rfqData?.originIso),
        getPortsByCode(token, rfqData?.destinationIso)
      );
      asyncTasks.push(getPorts(token));
    }
    Promise.all(asyncTasks).then(async (res) => {
      const providersData = await res[0].json();
      const incotermsData = await res[1].json();
      const trmData = await res[2].json();
      const loadTypesData = await res[3].json();
      const moveTypesData = await res[4].json();
      const originTerminalsData = await res[5].json();
      const destinationTerminalsData = await res[6].json();

      setProviders(providersData)
      setOriginBoardingTerminals(originTerminalsData);
      setDestinationBoardingTerminals(destinationTerminalsData);
      
      const defaultOr = originTerminalsData.find(
        (term) => term?.name === rfqData?.shipping?.origin_boarding_terminal?.city?.name
      );
      const defaultDes = destinationTerminalsData.find(
        (term) => term?.name === rfqData?.shipping?.destination_boarding_terminal?.city?.name
      );
      
      const shippingMethod = getValues("shipping_method");

      if (!getValues("service_details.loading_point") && !getValues("service_details.unloading_point")){
        if (shippingMethod === "ground"){
          setValue("service_details.loading_point", rfqData?.shipping?.origin_boarding_terminal?.city.name)

          setValue("service_details.unloading_point", rfqData?.shipping?.destination_boarding_terminal?.city.name)
        } else {
          setValue("service_details.loading_point", {
            label: `${defaultOr?.iata_code || defaultOr?.unlocs[0]} - ${
              defaultOr?.name
            } - ${defaultOr?.city} - ${defaultOr?.country}`,
            value: `${defaultOr?.iata_code || defaultOr?.unlocs[0]} - ${
              defaultOr?.name
            } - ${defaultOr?.city} - ${defaultOr?.country}`,
          });
          setValue("service_details.unloading_point", {
            label: `${defaultDes?.iata_code || defaultDes?.unlocs[0]} - ${
              defaultDes?.name
            } - ${defaultDes?.city} - ${defaultDes?.country}`,
            value: `${defaultDes?.iata_code || defaultDes?.unlocs[0]} - ${
              defaultDes?.name
            } - ${defaultDes?.city} - ${defaultDes?.country}`,
          });
        }
      }
      
      
      let moveTypes = [];
      let loadTypes = [];

      if (shippingMethod === "air") {
        moveTypes = moveTypesData.filter((type) =>
          [
            "Airport to airport",
            "Airport to door",
            "Door to door",
            "Door to port",
            "Ramp to ramp",
          ].includes(type.name)
        );
        loadTypes = loadTypesData.filter((type) =>
          ["Air", "Air priority", "Full charter", "Part charter"].includes(
            type.name
          )
        );
      } else if (['ocean','bbk','ro-ro','special containers'].includes(shippingMethod)) {
        moveTypes = moveTypesData.filter((type) =>
          [
            "Door to door",
            "Door to port",
            "Port to port",
            "Port to door",
            "Hook to hook",
            "Ramp to ramp",
          ].includes(type.name)
        );
        loadTypes = loadTypesData.filter((type) =>
          [
            "FCL",
            "FCL-OOG",
            "LCL",
            "Ro/Ro",
            "Breakbulk",
            "Full charter",
            "Part charter",
          ].includes(type.name)
        );
      } else {

        moveTypes = moveTypesData.filter((type) =>
          ["Nacional", "Urbano", "Internacional", "OTM", "DTA"].includes(type.name)
        );
        loadTypes = loadTypesData.filter((type) =>
        ["Expreso", "Consolidado", "Paqueteo"].includes(type.name)
        );
      }

      setCurrencyOptions([
        { label: "USD", value: 1 },
        { label: "COP", value: 1 / trmData.usd_cop_val },
        { label: "EUR", value: 1 / trmData.usd_eur },
      ]);

      setLoadTypeOptions(
        loadTypes.map((type) => {
          return {
            label: type.name,
            value: type.id,
          };
        })
      );
      setMoveTypeOptions(
        moveTypes.map((type) => {
          return {
            label: type.name,
            value: type.id,
          };
        })
      );

      let providerType = [9]; //Aerolinea apiV2
      if (URL==='https://apiqa.val.com.co/'){
        providerType=[4] //Aerolinea apiQA
      }
      
      if (shippingMethod === "ocean") {
        providerType = [6, 12, 13]; //V2 : NAVIERA BBK - NAVIERA CTR - NAVIERA RORO     
        if (URL==='https://apiqa.val.com.co/'){
          providerType = [6, 7]; //QA : Bodega - Naviera 
        }
      }
      if (shippingMethod === "ground") {
        providerType = [10, 11]; //V2 : TERRESTRE - TERRESTRE INTERNACIONAL 
        if (URL==='https://apiqa.val.com.co/'){
          providerType = [6, 7]; //QA : Bodega - Naviera  (no hay terrestres)
        }
      }
      console.log("providersData: ",providersData)

      setAirlines(
        providersData.filter((provider) =>
          providerType.includes(provider.provider_type)
        )
      );
      setIncoterms(incotermsData);

      const allTerminalsData = await res[7].json();
      let terminals = allTerminalsData

      if (['ground','ocean','bbk','ro-ro','special containers'].includes(getValues("shipping_method"))){
        terminals =  allTerminalsData.map((e) => e[1]);
      }
      setAllTerminals(terminals);
    });
  }, []);

  React.useEffect(() => {
    const subscription = methods.watch(
      (value, { name, type }) => {
        if (value.shipping_method === "ocean") {
          //OCEAN
          if (
            name === "shipping_information.volume" ||
            name === "shipping_information.weight"
          ) {
            setValue(
              "shipping_information.chargeable_vol",
              Math.max(
                value.shipping_information?.volume,
                value.shipping_information?.weight
              ).toFixed(2)
            );
          }
        } else {
          //NO OCEAN
          if (
            name === "shipping_information.volume" ||
            name === "shipping_information.pieces"
          ) {
            setValue(
              "shipping_information.chargeable_vol",
              ((value?.shipping_information?.volume || 0 )/ 6000).toFixed(2)
            );
          }
        }
        if (name === "shipping_information.currency") {
          setValue(
            "shipping_information.exchange",
            value?.shipping_information?.currency?.value?.toFixed(2)
          );
        }
        if (name === "shipping_information.dangerous_goods") {
          if (value.shipping_information.dangerous_goods.value) {
            setDisableUN(false);
          } else {
            setDisableUN(true);
            setValue("shipping_information.UN", "");
          }
        }

        return () => subscription.unsubscribe();
      },
      [methods.watch]
    );
  });
  
  function formatItem(item_type, preliquidationData){
    const list = preliquidationData?.items?.filter(item => item.item_type === item_type)?.map((item, i)=>{
      const Unit = unitOptions.find(unit=> unit.label?.toLowerCase() === item.unit.toLowerCase())
      const Currency =  currencyOptions.find(option => option.label === item.currency) || {label:'USD', value:1}
      console.log("Currency :",Currency)
      return {
        itemId:i+1,
        Concepto: item_type=='OCEAN'?
          {
            label:item.concept,
            value:item.concept,
          }:
          item.concept,
        Factor: item.factor === null? 1 : item.factor,
        Unit,
        Trm: Currency?.value ? 1 / Currency?.value : 1,
        Currency,
        Rate: item.item_rate,
        Min: item.min,
        'Total in USD':item.total_in_usd,
      }
    })
    if (list.length){
      return list;
    } else return [servicesDefaultData,{...servicesDefaultData,itemId:2},{...servicesDefaultData,itemId:3}]
  }
  function formatNet(item_type, preliquidationData){
    const data = preliquidationData
    const list = data?.items?.filter(item => item.item_type === item_type)?.map((item, i)=>{
      const net = item.rate || item.ocean_rate || 0
        const provider = providers?.find(prov=> prov.id === net.provider)
        return {
          itemId:i+1,
          Concepto: net.concept,
          Net: net.net_rate,
          Minima: net.min_rate,
          Total: net.total,
          Profit: net.profit,
          Proveedor: {
            label:provider?.name,
            value:provider?.id,
          },
        }
    })
    const filteredList = list.filter(item => item !== undefined)
    if (list.length){
      return list;
    } else return [netDefaultData]
  }
  

  const handleFile = async (e) => {
    setLoadingExcel(true)
    if (e?.target?.files.length > 0) {
      const file = e.target.files[0]
      uploadPreliquidationExcel(token, file).then(async res => {
        setOpen(true)
        const preliquidationData = await res.json();
        console.log("preliquidationData :",preliquidationData)
        const shippingMethod = getValues("shippingMethod")
        const moveType = moveTypeOptions.find(option => option.label?.toLowerCase() == preliquidationData.service_details?.move_type?.toLowerCase())

        const loadType = loadTypeOptions.find(option => option.label?.toLowerCase() == preliquidationData.service_details?.load_type?.toLowerCase())
        console.log("loadType :",loadType)
        const packaging_list =  preliquidationData?.packaging_lists?.map((item,i) => {
          return {
            itemId:1+i,
            '#':item.number,
            Descripcion: item.description,
            Qty: item.qty,
            'Largo(m)': item.length,
            'Ancho(m)': item.width,
            'Alto(m)': item.height,
            'Volumen por pieza(m³)': item.volume_per_piece,
            'CBM(m³)': item.cbm,
            'Peso por pieza(Kg)':item.weight_per_piece,
            'Peso total(Kg)': item.total_weight,
            stowage:{label: item.stowage, value: item.stowage},
          }
        })
        let filteredPackagingList = packaging_list.filter(item => item.Qty)
        const preliquidationValues =  {
          packaging_list:filteredPackagingList,
          service_details:{
            incoterm:{
              label:preliquidationData.service_details.incoterm,
              value:preliquidationData.service_details.incoterm,
            },
            destination_incoterm:{
              label:preliquidationData.service_details.destination_incoterm,
              value:preliquidationData.service_details.destination_incoterm,
            },
            valid_until:format(parseISO(preliquidationData.service_details.valid_until), 'yyyy-MM-dd'),

            move_type: moveType,
            load_type: loadType,
            transit_time: preliquidationData.service_details.transit_time,
            airline:{
              label:preliquidationData.service_details.airline,
              value:preliquidationData.service_details.airline,
            },
            transshipment_via:{
              label:preliquidationData.service_details.transshipment_via,
              value:preliquidationData.service_details.transshipment_via,
            },
            loading_point: shippingMethod === 'ground'? preliquidationData.service_details.loading_point : {
              label:preliquidationData.service_details.loading_point,
              value:preliquidationData.service_details.loading_point,
            },
            unloading_point: shippingMethod === 'ground'? preliquidationData.service_details.unloading_point :{
              label:preliquidationData.service_details.unloading_point,
              value:preliquidationData.service_details.unloading_point,
            },
          },
          shipping_information:{
            // pieces:totalAmount,
            // volume:totalVolume.toFixed(2),
            // weight:totalWeightTons.toFixed(2),
            chargeable_vol: preliquidationData.shipping_information.chargeable_vol,
            commodity:preliquidationData.shipping_information.commodity,
            dangerous_goods:preliquidationData.shipping_information.dangerous_goods?{label:'SI',value:true}:{label:'NO',value:false},
            UN:preliquidationData.shipping_information.UN,
            cargo_value:preliquidationData.shipping_information.cargo_value,
            TRM:preliquidationData.shipping_information?.TRM || getValues("shipping_information.TRM") || 1,
            currency : currencyOptions?.find(option => option.label === preliquidationData.shipping_information.currency) || {label:'USD', value:1},
            exchange: (1 / currencyOptions?.find(option => option.label === preliquidationData.shipping_information.currency)?.value) || 1,
            // usd_eur: trmData.usd_eur,
          },
          origin_list: formatItem("ORIGIN", preliquidationData),
          net_origin: formatNet("ORIGIN", preliquidationData),
          
          destination_list: formatItem("DESTINATION", preliquidationData),
          net_destination: formatNet("DESTINATION", preliquidationData),
  
          // insurance_list: formatItem("INSURANCE", preliquidationData),
          // net_insurance: formatNet("INSURANCE", preliquidationData),

          insurance_list: formatItem("SEGURO", preliquidationData),
          net_insurance: formatNet("SEGURO", preliquidationData),
          
          ocean_freight: formatItem("OCEAN", preliquidationData),
          net_ocean_freight: formatNet("OCEAN", preliquidationData),
        }
        console.log("preliquidationValues :",preliquidationValues)
        console.log("FORM :",{
          ...getValues(),
          ...preliquidationValues,
        })
        reset({
          ...getValues(),
          ...preliquidationValues,
        })
        
        methods.setValue("packaging_list",filteredPackagingList)
        setLoadingExcel(false)
        // setValue("service_details",preliquidationValues.service_details)
        // setValue("shipping_information",preliquidationValues.shipping_information)
        // setValue("origin_list",preliquidationValues.origin_list)
      })
    }
}
  console.log('shippingMethod :',getValues("shipping_method"))
  return (
    <Container>
      <WarningModal open={open} setOpen={setOpen}/>
      <div
        className="subtitle"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h3>Detalles del servicio</h3>
        <h3>{rfqData?.radication}</h3>
        <div style={{ display: "flex", gap: "10px" }}>
        <h3>{`${rfqData?.shipping?.origin_boarding_terminal.city.country.name} -> ${rfqData?.shipping?.destination_boarding_terminal.city.country.name}`}</h3>
          <Icon
            name={
              getValues("shipping_method") === "air"?"Aereo" :
              getValues("shipping_method") === "ocean"?"Maritimo" :
              getValues("shipping_method") === "ground"?"Terrestre":
              getValues("shipping_method") === "bbk"?"Bbk":
              getValues("shipping_method") === "ro-ro"?"Roro":
              "Containers"
            }
            size="31"
            color="#3D509A"
          />
        </div>
      </div>
      <div style={{gridColumn:'span 2'}}>
          <InputFileCard onChange={handleFile} accept='application/xsl' label='Cargar con excel' name='provider-rate' startIcon={{ name: 'Upload', color: '#FFF' }} width={'100%'} aspectRatio={'10/1'} />
      </div>
      <div>
        <SmallSelect
          name="service_details.move_type"
          label="Tipo de movimiento :"
          options={moveTypeOptions}
        />
        <SmallSelect
          name="service_details.load_type"
          label="Tipo de carga :"
          options={loadTypeOptions}
        />
        <SmallSelect
          name="service_details.incoterm"
          label="Incoterm :"
          options={incoterms.map((incoterm) => ({
            label: incoterm.name,
            value: incoterm.id,
          }))}
        />
        <SmallSelect
          name="service_details.destination_incoterm"
          label="Incoterm de destino :"
          options={incoterms.map((incoterm) => ({
            label: incoterm.name,
            value: incoterm.id,
          }))}
        />
        <SmallField
          name="service_details.transit_time"
          label="Tiempo en transito :"
          isRequiered={true}
        />
      </div>
      <div>
        <SmallSelect
          name="service_details.airline"
          label={getValues("shipping_method") === "ground"? "Transportadora :" : "LINER :"}
          required={false}
          options={[{label:"N/a",value:""},...airlines.map((line) => ({
            label: line.name,
            value: line.id,
          }))]}
        />
        {
          getValues("shipping_method") === "ground"?
          <SmallField
            name="service_details.loading_point"
            label="Punto de carga :"
            type="text"
          /> : 
          <SmallSelect
          name="service_details.loading_point"
          label="Punto de carga :"
          options={originBoardingTerminals?.map((e) => {
            return {
              label: getValues("shipping_method") === "air"?
                `${e?.iata_code} - ${e?.name} - ${e?.city} - ${e?.country}`:
                `${e?.unlocs[0]} - ${e?.name} - ${e?.province} - ${e?.country}`,
              value: `${e?.name} - ${e?.city} - ${e?.country}`,
            };
          })}
        />
        }
        
        {
          getValues("shipping_method") === "ground"?
          <SmallField
            name="service_details.unloading_point"
            label="Punto de descarga :"
            type="text"
          /> : 
          <SmallSelect
            name="service_details.unloading_point"
            label="Punto de descarga :"
            options={destinationBoardingTerminals?.map((e) => {
              return {
                label:  getValues("shipping_method") === "air"?`${e.iata_code} - ${e.name} - ${e.city} - ${e.country}` :
                ['ocean','bbk','ro-ro','special containers'].includes(getValues("shipping_method"))?`${e.unlocs[0]} - ${e.name} - ${e.province} - ${e.country}`:
                        `${e.name}`,
                value: `${e.name} - ${e.city} - ${e.country}`,
              };
            })}
          />

        }
        
        <SmallSelect
          name="service_details.transshipment_via"
          label="Transbordo vía :"
          required={false}
          options={allTerminals?.map((e) => {
            return {
              label:  getValues("shipping_method") === "air"?`${e.iata_code} - ${e.name} - ${e.city} - ${e.country}` :
              ['ocean','bbk','ro-ro','special containers'].includes(getValues("shipping_method"))?`${e.unlocs[0]} - ${e.name} - ${e.province} - ${e.country}`:
                `${e.name}`,
              value: `${e.name} - ${e.city} - ${e.country}`,
            };
          })}
        />
        <SmallField
          name="service_details.valid_until"
          label="Valido hasta :"
          type="date"
        />
      </div>
      <div className="subtitle">
        <h3>Packaging list</h3>
      </div>
      <div style={{ gridColumn: "span 2" }}>
        {loadingExcel? <Loading/> : <PackagingList trigger={trigger} />}
      </div>
      <div className="subtitle">
        <h3>Informacion de envio</h3>
      </div>
      <div>
        <SmallField
          type="number"
          name="shipping_information.pieces"
          label="Piezas :"
          bold
        />
        <SmallField
          type="number"
          name="shipping_information.volume"
          label="Volumen (cm³):"
        />
        <SmallField
          type="number"
          name="shipping_information.weight"
          label="Peso (Kg):"
        />
        {
          ['ocean','bbk','ro-ro','special containers'].includes(getValues("shipping_method")) ? (
            <SmallField
              type="number"
              name="shipping_information.chargeable_vol"
              label="Revenue Tons"
            />
          ) : (
            <Tooltip
              content="volumen(cm³) /  6000"
              placement="topEnd"
              color="primary"
              style={{ width: "100%" }}
            >
              <SmallField
                type="number"
                name="shipping_information.chargeable_vol"
                label="Volumen cargable (kg):"
              />
            </Tooltip>
          )
        }
        <SmallField name="shipping_information.commodity" label="Commodity :" />
      </div>
      <div>
        <SmallSelect
          name="shipping_information.dangerous_goods"
          label="Bienes peligrosos :"
          options={[
            { label: "SI", value: true },
            { label: "NO", value: false },
          ]}
        />
        <SmallField
          req
          disabled={disableUN}
          name="shipping_information.UN"
          label="UN :"
          required={false}
        />
        <SmallField
          type="number"
          name="shipping_information.cargo_value"
          label="Valor de la carga :"
        />
        <SmallField
          type="number"
          name="shipping_information.TRM"
          label="TRM :"
        />
        <SmallField
          type="number"
          name="shipping_information.usd_eur"
          label="Euro en USD:"
        />
      </div>
    </Container>
  );
}