import React, { useEffect, useState } from 'react'
import { getPreliquidations, getProviders } from '../../../../services/preliquidation.services'
import { useSelector } from 'react-redux'
import Table from '../../../../components/Table/Table';
import { Loading, styled } from '@nextui-org/react';

const columns = [
    { name: 'Tipo de gasto', uid: 'item_type', type: 'text' },
    { name: 'Proveedor', uid: 'provider', type: 'text' },
    { name: 'Servicio', uid: 'service', type: 'text' },
    { name: 'Und', uid: 'unit', type: 'text' },
    { name: 'Factor', uid: 'factor', type: 'text' },
    { name: 'Venta por unidad', uid: 'sale_price_unit', type: 'text' },
    { name: 'Moneda', uid: 'sale_currency', type: 'text' },
    { name: 'trm', uid: 'sale_trm', type: 'text' },
    { name: 'Venta USD', uid: 'sale_in_usd', type: 'text' },
    { name: 'Compra', uid: 'purchase_price', type: 'text' },
    { name: 'Profit (USD)', uid: 'profit', type: 'text' },
];
const columnsAir = [
    { name: 'Gastos carga aerea', uid: 'concept', type: 'text' },
    { name: 'Provider', uid: 'provider', type: 'text' },
    { name: 'Currency', uid: 'currency', type: 'text' },
    { name: 'Frequency', uid: 'frequency', type: 'text' },
    { name: 'Min', uid: 'min', type: 'text' },
    { name: 'Price per kg (USD)', uid: 'price_per_kg', type: 'text' },
    { name: 'Total venta(USD)', uid: 'sale', type: 'text' },
    { name: 'Total compra(USD)', uid: 'purchase', type: 'text' },
    { name: 'Profit (USD)', uid: 'profit', type: 'text' },
];

const Container = styled("div",{
    display:'flex',
    flexDirection:'column',
    gap:'30px',
    '.totals':{
        display:'flex',
        justifyContent:'end',
        gap:'40px',
        padding:'20px',
        borderRadius:'6px',
        margin:'10px 0',
        background:'$primary_300',
        color:'white',
    }
})

export default function PreliquidationTable2({currentQuoteData}) {
    const token = useSelector(state=>state.user.token)
    const [tableData, setTableData] = useState({})
    const [totals, setTotals] = useState()
    const [loading, setLoading] = useState(false)

    function formatItems(items,providersData){
        return items.map(item => {
            const purchase_price = item.rate?.total || 0
            return {
                id:item.id,
                item_type:item.item_type,
                provider: providersData.find(prov=>prov.id == item.rate?.provider)?.name,
                service:item.concept,
                unit:item.unit,
                factor:item.factor,
                sale_in_usd:item.total_in_usd,
                sale_price_unit:item.item_rate,
                sale_currency:item.currency,
                sale_trm:item.trm,
                purchase_price,
                profit: item.rate?.profit || item.total_in_usd - purchase_price,
            }
        })
    }
    function formatAirCargo(item,providersData){
        const freight = item.air_rate[0]
        const surcharge = item.air_rate[1]
        const profit = Number(item.total_freight_usd - freight?.total)
        const formattedAirCargo = []
        if (freight){
            formattedAirCargo.push({
                id:freight?.id || 998,
                concept:'Freight',
                provider: providersData.find(prov=>prov.id == freight?.provider)?.name,
                currency:item.currency,
                frequency:item.frequency,
                min:freight?.min_rate,
                price_per_kg:freight?.kg,
                sale:item.total_freight_usd,
                purchase:freight?.total,
                profit:profit && profit.toFixed(2)
            })
        }
        if (surcharge){
            formattedAirCargo.push({
                id:surcharge?.id|| 999,
                concept:'Fuel surcharge',
                provider: surcharge?.provider,
                currency:item.currency,
                frequency:item.frequency,
                min:surcharge?.min_rate,
                price_per_kg:surcharge?.kg,
                sale:item.total_fuel_surcharge_usd,
                purchase:surcharge?.total,
                profit:item.total_fuel_surcharge_usd - surcharge?.total
            } )
        }
        console.log("formattedAirCargo :",formattedAirCargo)
        return formattedAirCargo
    }

    function getData(){
        setLoading(true)
        if (!currentQuoteData || !currentQuoteData?.preliquidation_ids?.length){
            setLoading(false)
            return
        }
        const currentPreliquidationId = currentQuoteData?.preliquidation_ids[0]
        const asyncTasks = [getPreliquidations(token, currentPreliquidationId), getProviders(token)]
        Promise.all(asyncTasks).then(async res => {
            const preliquidationData = await res[0].json();
            const providersData = await res[1].json();
            const items = preliquidationData.items;
            const originItems = items.filter(item => item.item_type == 'ORIGIN')
            const destinationItems = items.filter(item => item.item_type == 'DESTINATION')
            const insuranceItems = items.filter(item => item.item_type == 'INSURANCE')
            const oceanItems = items.filter(item => item.item_type == 'OCEAN')
            const profitShareItems = items.filter(item => item.item_type == 'PROFIT-SHARE')
            const airCargo = preliquidationData.air_cargo
            
            const data = {
                origin: originItems.length? formatItems(originItems, providersData) : [],
                destination: destinationItems.length? formatItems(destinationItems, providersData) : [],
                insurance: insuranceItems.length? formatItems(insuranceItems, providersData) : [],
                ocean: oceanItems.length? formatItems(oceanItems, providersData) : [],
                profitShare: profitShareItems.length? formatItems(profitShareItems, providersData) : [],
                airCargo: airCargo ? formatAirCargo(airCargo, providersData) : [],
            }

            const mainTable = [...data.origin,...data.destination,...data.insurance,...data.ocean,...data.profitShare]
            let saleTotal = 0;
            let purchaseTotal = 0;
            let totalProfit = 0;
            let mainSaleTotal = 0;
            let mainPurchaseTotal = 0;
            let mainTotalProfit = 0;

            mainTable.forEach(item=>{
                mainSaleTotal += item.sale_in_usd || 0
                mainPurchaseTotal += item.purchase_price || 0
                mainTotalProfit += Number(item.profit || 0)
            })
            saleTotal = mainSaleTotal;
            
            purchaseTotal = mainPurchaseTotal;
            totalProfit = Number(mainTotalProfit);
            if (data.airCargo?.length){
                saleTotal += data.airCargo[0]?.sale || 0
                purchaseTotal += data.airCargo[0]?.purchase || 0
                totalProfit += Number(data.airCargo[0]?.profit || 0)

                saleTotal += data.airCargo[1]?.sale || 0
                purchaseTotal += data.airCargo[1]?.purchase || 0
                totalProfit += Number(data.airCargo[1]?.profit || 0)
            }
            setTotals({ 
                sale:saleTotal.toFixed(2),
                purchase:purchaseTotal.toFixed(2),
                profit:totalProfit.toFixed(2),
            })
            const formatedData = {
                main:[
                    ...data.origin,
                    ...data.destination,
                    ...data.insurance,
                    ...data.ocean,
                    ...data.profitShare,
                    {
                        id:5003456789,
                        factor: "",
                        item_type: "TOTAL",
                        profit: mainTotalProfit?.toFixed(2),
                        provider: null,
                        purchase_price: mainPurchaseTotal?.toFixed(2),
                        sale_currency: "",
                        sale_in_usd: mainSaleTotal?.toFixed(2),
                        sale_price_unit: "",
                        sale_trm: "",
                        service: "",
                        unit: "",
                    }
                ],
            }
            if (data.airCargo?.length){
                const sale = data.airCargo[0]?.sale + data.airCargo[1]?.sale
                const purchase = data.airCargo[0]?.purchase + data.airCargo[1]?.purchase
                const profit = Number(data.airCargo[0]?.profit )+ Number(data.airCargo[1]?.profit)
                
                formatedData.airCargo = [
                    ...data.airCargo,
                    {
                        id:999999999999,
                        concept:"TOTAL",
                        provider: "",
                        currency:"",
                        frequency:"",
                        min:"",
                        price_per_kg:"",
                        sale:sale?sale.toFixed(2):0,
                        purchase:purchase?purchase.toFixed(2):0,
                        profit:profit? profit.toFixed(2) : 0,
                    }
                ]
            }
            setTableData(formatedData)
            setLoading(false)
        }).catch(err=>console.log("err :",err))
    }

    useEffect(()=>{
        getData()
    },[currentQuoteData])

    return (
        loading?<Loading/>:
        !currentQuoteData? null :
        !currentQuoteData.preliquidation_ids[0]? <p>Aun no tiene preliquidación</p>:
        <Container>
            {tableData.main?.length>1 && <div>
                <Table columns={columns} items={tableData.main} searchBar={false} excel={false} pagination={false} hidePagination/>
            </div>}
            {tableData.airCargo && <div>
                <Table columns={columnsAir} items={tableData.airCargo} searchBar={false} excel={false} pagination={false} hidePagination/>
            </div>}
            <div className='totals'>
                <b>Sale total {totals?.sale}</b>
                <b>Purchase total {totals?.purchase}</b>
                <b>Profit {totals?.profit}</b>
            </div>
        </Container>
    )
}
