import React, { useEffect, useState } from 'react';
import { Collapse, Loading, styled } from '@nextui-org/react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { getOffers } from '../../../services/quotations.services';
import { useSearchParams } from 'react-router-dom';
import { getLatLng } from '../../../services/map.services';
import { InitMap } from '../../../functions/mapFunctions';
// Components
import Button from '../../../components/Button/Button';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { getLoadTypes, getMoveTypes, getPreliquidations } from '../../../services/preliquidation.services';
import OfferCard from './OfferCard';
import { getPortsByCountry, getAirportsByCountry, getCountriesNew, getPortsByCode } from '../../../services/countries.services';
import { getServiceSplitQuote } from '../../../services/quotation.services';
import { getPreliquidationCosts } from '../../functions';
import Icon from '../../../components/Icon/Icon';
import { getUsers } from '../../../services/user.services';
import { getBusinessTerms } from '../../../services/businessTerms.services';
import { getShippingMethods } from '../../../services/shippingMethods.services';

const Container = styled("div", {
    display: 'flex',
    position:'relative',
    flexDirection: 'column',
    background: 'white',
    alignItems: 'center',
    boxShadow:'$elevation_1',
    gap: '20px',
    width: '95%',
    height:'fit-content',
    margin: '3rem auto',
    padding: '20px',
    borderRadius: '8px',
    ['.labels']: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: '10px',

    },
    ['.info']: {
        display: 'flex',
        marginLeft: '30px',
        gap: '10px',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    "@media (max-width:1000px)":{
        fontSize:'10px',
    },
})

const InfoCard = styled("div", {
    margin:'0 20px',
    padding: '10px',
    fontSize:'14px',
    '.title': {
        margin: '10px 0',
    },
    'div':{
        margin:'5px',
        display:'flex',
        borderBottom:'1px solid $neutral_300',
        justifyContent:'space-between',
    },
    "@media (max-width:1000px)":{
        fontSize:'10px',
    },
})

const DataContainer = styled("div", {
    display: 'grid',
    width:'100%',
    gridTemplateColumns: '40% 60%',
    gap: '10px',
    "@media (max-width:1000px)":{
        display:"block",
        fontSize:'10px',
    },
})
const TableContainer = styled("div", {
    gridColumn: 'span 2',
})
const StyledTable = styled("table",{
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: '20px',
    margin:'auto',
    'th, td': {
        'border': '1px solid #ddd',
        'padding': '4px',
        'text-align': 'left',
    },
    'th': {
        'background-color': '#f2f2f2',
    },
    'tr:nth-child(even)': {
        'background-color': '#f9f9f9',
    },
})

const ShipmentContainer = styled("div", {
    width:'100%',
    gridColumn:'span 2',
    display:'flex',
    justifyContent:'space-around',
    alignItems:'center',
    background:'$primary_300',
    color:'white',
    padding:'20px',
    marginBottom:'25px',
    borderRadius:'6px',
    'h3':{margin:0, fontSize:'20px'},
    'span':{fontSize:'12px'},
    "@media(max-width:1000px)":{
        width:'90%',
        margin:'auto',
        padding:'5px',
        'h3':{margin:0, fontSize:'9px'},
        'span':{fontSize:'10px'},
    }
})
const CollapseTitle = styled("div", {
    display:'flex',
    gap:'15px',
    color:'$primary_300',
    fontSize:'20px',   
})
const StyledMap = styled("div",{
    "@media(max-width:1000px)":{
        height:'300px',
        width:'90%',
        margin:'auto'
    }
})

export default function Costs() {
    const [searchParams] = useSearchParams();
    const radication = searchParams.get('radication')
    const quoteId = searchParams.get('id')
    const user = useSelector((state) => state.user);
    const token = user.token

    const navigate = useNavigate()

    const [loading, setLoading] = useState(true)
    const [items, setItems] = useState([])
    const [clientChose, setClientChose] = useState(false)
    const [RFQData, setRFQData] = useState({})
    const [client, setClient] = useState({})
    const [shippingMethod, setShippingMethod] = useState()
    const [incoterms, setIncoterms] = useState([])

    function getData() {
        setLoading(true)
        const asyncTasks = [getOffers(token, radication), getPreliquidations(token), getServiceSplitQuote(quoteId, token), getUsers(token), getShippingMethods(token), getBusinessTerms(token)]
        Promise.all(asyncTasks).then(async res => {
            const offersData = res[0]
            const preliquidationsData = await res[1].json();
            const rfqData = await res[2].json();
            const usersData = await res[3].json();
            const shippingMethodsData = await res[4].json();
            const incotermsData = await res[5].json();
            console.log("RFQData :",rfqData)
            setIncoterms(incotermsData)
            setRFQData(rfqData)

            const currentShippingMethod = shippingMethodsData.find(method=>method.id === rfqData.shipping.shipping_method)?.name

            setShippingMethod(currentShippingMethod)

            let clientId = rfqData.users.find(user => ['Usuario','Cliente'].includes(user.user?.groups[0]))?.user?.user
            if (!clientId){clientId = rfqData?.users[0]?.user?.user}

            const clientData = usersData.find(user => user.id === clientId)

            setClient(clientData)

            if (offersData?.length > 0) {
                const formattedData = offersData.map((offer, i) => {
                    if (offer.customer_approval) {
                        setClientChose(true)
                    }
                    const lastPreliquidationId = offer.preliquidation_ids[offer.preliquidation_ids?.length - 1]
                    const lastPreliquidation = preliquidationsData.find(prel => prel.id === lastPreliquidationId)
                    const { totalCost } = getPreliquidationCosts(lastPreliquidation)
                    const totalsByItemType = {};
                    lastPreliquidation?.items?.forEach(item => {
                        const item_type = item.item_type;
                        const total_in_usd = item.total_in_usd;

                        if (!totalsByItemType[item_type]) {
                            totalsByItemType[item_type] = total_in_usd;
                        } else {
                            totalsByItemType[item_type] += total_in_usd;
                        }
                    });
                    return {
                        ...offer,
                        air_cargo: lastPreliquidation.air_cargo,
                        totalsByItemType: totalsByItemType,
                        option: i + 1,
                        cost: '$' + totalCost.toFixed(2),
                    }
                })
                setItems(formattedData)
            }
            switch (currentShippingMethod) {
                case 'ground':
                    const asyncTasksGround = [
                        getLatLng(rfqData.shipping.origin_boarding_terminal.city.country.name, token),
                        getLatLng(rfqData.shipping.destination_boarding_terminal.city.country.name, token)
                    ]
                    Promise.all(asyncTasksGround).then(async responses => {
                        const originCoordsData = {
                            lat: responses[0].location.lat,
                            lng: responses[0].location.lng,
                            label: 'Origen',
                        }
                        const destinationCoordsData = {
                            lat: responses[1].location.lat,
                            lng: responses[1].location.lng,
                            label: 'Destino',
                        }
                        InitMap([originCoordsData, destinationCoordsData] ,'rfqMap')
                    })
                    
                    break;
                case 'air':
                    const asyncTasksAir = [
                        getAirportsByCountry(token, rfqData.shipping.origin_boarding_terminal.city.country.name),
                        getAirportsByCountry(token, rfqData.shipping.destination_boarding_terminal.city.country.name)
                    ]
                    Promise.all(asyncTasksAir).then(async res => {
                        const originAirports = await res[0].json();
                        const destinationAirports = await res[1].json();
                        const originPort = originAirports?.find(port => port.name === rfqData.shipping.origin_boarding_terminal.city.name)
                        const destinationPort = destinationAirports?.find(port => port.name === rfqData.shipping.destination_boarding_terminal.city.name)
                        
                        const originCoordinates = originPort._geoloc
                        const destinationCoordinates = destinationPort._geoloc
                        const originCoordsData = {
                            lat: originCoordinates.lat,
                            lng: originCoordinates.lng,
                            label: 'Origen',
                        }
                        const destinationCoordsData = {
                            lat: destinationCoordinates.lat,
                            lng: destinationCoordinates.lng,
                            label: 'Destino',
                        }
                        InitMap([originCoordsData, destinationCoordsData],'rfqMap')
                    })
                    break;
                default:
                    getCountriesNew(token).then(async res => {
                        const countriesData = await res.json()
                        const originCountry = countriesData.find(country => country.name?.toLowerCase() === rfqData.shipping.origin_boarding_terminal.city.country.name?.toLowerCase())
                        const destinationCountry = countriesData.find(country => country.name?.toLowerCase() === rfqData.shipping.destination_boarding_terminal.city.country.name?.toLowerCase())

                        const asyncTasksOcean = [
                            getPortsByCode(token, originCountry?.code),
                            getPortsByCode(token, destinationCountry?.code),
                        ]
                        Promise.all(asyncTasksOcean).then(async res => {
                            const originPorts = await res[0].json();
                            const destinationPorts = await res[1].json();
                            
                            const originPort = originPorts.find(port => port.name === rfqData.shipping.origin_boarding_terminal.city.name)
                            const destinationPort = destinationPorts.find(port => port.name === rfqData.shipping.destination_boarding_terminal.city.name)
    
                            const originCoordinates = {
                                lng: originPort?.coordinates[0],
                                lat: originPort?.coordinates[1],
                            }
                            const destinationCoordinates = {
                                lng: destinationPort?.coordinates[0],
                                lat: destinationPort?.coordinates[1],
                            }
                            InitMap([originCoordinates, destinationCoordinates],'rfqMap')
                        })
                    })
                    
                    break;
            }
            setLoading(false)
        })
    }
    useEffect(() => {
        getData()
    }, [])

    const labels = {
        "temperature_control": {
            es: "Requiere control de Temperatura",
            en: "Temperature control required",
        },
        "dangerous": {
            es: "La carga es peligrosa",
            en: "Dangerous goods",
        },
        "data_logger": {
            es: "Requiere registro de datos",
            en: "Data logger required",
        },
        "remountable": {
            es: "La carga es remontable",
            en: "Remountable cargo",
        },
        "arancelary_position": {
            es: "Requiere posicion arancelaria",
            en: "Tariff position required",
        },
        "route_study": {
            es: "Requiere estudio de ruta",
            en: "Route study required",
        },
        "security_analysis": {
            es: "Requiere analisis de seguridad",
            en: "Security analysis required",
        },
        "additional_specifications": {
            es: "Especificaciones adicionales",
            en: "Additional specifications",
        },
    };

    return (
        <Container>
            <PageTitle>
                {`Pre - Liquidación #${radication}`}
            </PageTitle>
            {clientChose && <Button text="Ir a detalles del servicio" handler={() => navigate(`/services/detail/chat/?radication=${RFQData.radication}&tab=chat`)} />}
            <div style={{position:'absolute', top:'20px', right:'20px'}}>
            </div>
            <div style={{ width:'100%' }}>
                {loading ? <Loading size='xl' style={{margin:'auto'}}/> :
                    <div>
                        <span style={{ margin: 'auto', textAlign:'center' }}>{items.length > 0 ?
                            'La cotizacion esta lista para su aprobacion, a continuacion se presentan las opciones disponibles.' :
                            'Pronto nuestro equipo atenderá su solicitud y se pondrá en contacto con usted para brindarle más información y ayudarle en lo que necesite.'
                        }</span>
                        {
                            items.length > 0 &&
                            <Collapse 
                            title={clientChose?
                            <CollapseTitle>Ya elegiste una oferta  <Icon color='green' size='35px' name='CheckModal'/></CollapseTitle>:
                            <CollapseTitle>Elige una oferta  <Icon name='WarnModal' color='red' size='35px'/></CollapseTitle>}
                            >
                                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap', gap: '40px' }}>
                                    {items.map((item) => {
                                        return <OfferCard key={item.id} clientChose={clientChose} data={item} getData={getData} shippingMethod={RFQData.shipping?.shipping_method}/>
                                    })}
                                </div>
                            </Collapse>
                        }
                        
                    </div>
                }
            </div>
            {RFQData.shipping && RFQData.service_options &&
                <DataContainer>
                    <ShipmentContainer>
                        <div>
                            <h3>{RFQData.shipping?.origin_boarding_terminal?.city?.name}, {RFQData.shipping?.origin_boarding_terminal?.city?.country?.name}</h3>
                            <span>Direccion : {RFQData.shipping?.pickup_address==="Ninguno"?'N/a':RFQData.shipping?.pickup_address}</span>
                        </div>
                        <Icon name='ArrowRight' color='white'/>
                        <div>
                            <Icon
                                name={
                                    shippingMethod === "air"
                                    ? "Aereo"
                                    :['ocean','bbk','ro-ro','special containers'].includes(shippingMethod)
                                    ? "Maritimo"
                                    : "Terrestre"
                                }
                                size="40"
                                color="white"
                            />
                            <h3>{RFQData?.business_terms}</h3>
                        </div>
                        
                        <Icon name='ArrowRight' color='white'/>
                        <div>
                            <h3>{RFQData.shipping?.destination_boarding_terminal?.city?.name}, {RFQData.shipping?.destination_boarding_terminal?.city?.country?.name}</h3>
                            <span>Direccion : {RFQData.shipping?.delivery_address === "Ninguno"?'N/a': RFQData.shipping?.delivery_address}</span>
                        </div>
                    </ShipmentContainer>
                    <InfoCard>
                        <p className='title'>General</p>
                            <div>
                                <b>Radicado : </b>
                                <span>{RFQData.radication}</span>
                            </div>
                            <div>
                                <b>Tipo de Negocio : </b>
                                <span>{RFQData.business_type}</span>
                            </div>
                            <div>
                                <b>Método de envio : </b>
                                <span>
                                    {shippingMethod}
                                </span>                              
                            </div>
                            <div>
                                <b>Incoterm : </b>
                                <span>
                                    {incoterms?.find(term => term.id === RFQData.business_term)?.name}
                                </span>
                            </div>
                    </InfoCard>
                    
                    <StyledMap 
                        id='rfqMap'
                        className='w-100 h-100 border-rounded'
                        style={{gridRow:'span 3'}}
                    />

                    <InfoCard>
                        <p className='title'>Cliente</p>
                        <div>
                            <b>Nombre : </b>
                            <span>{client?.name} {client?.last_name}</span>
                        </div>
                        <div>
                            <b>Email : </b>
                            <span>{client?.email}</span>
                        </div>
                        <div>
                            <b>Telefono : </b>
                            <span>{client?.phone}</span>
                        </div>
                    </InfoCard>
                    <InfoCard>
                        <p className='title'>Adicional</p>
                        {
                            Object.keys(RFQData.service_options).map(key => {
                                if (labels[key] && RFQData?.service_options[key] !== 0) {
                                    if (key === 'additional_specifications') {
                                        return (
                                            <div key={key}>
                                                <b>{labels[key].es} :</b>
                                                <span >
                                                    {RFQData?.service_options?.additional_specifications}
                                                </span>
                                            </div>
                                            
                                        );
                                    } else {
                                        return (
                                            <div key={key}>
                                                <b>{labels[key]?.es}</b>
                                                <span>Si</span>
                                            </div>
                                        );
                                    }
                                }
                            })
                        }
                    </InfoCard>
                    <InfoCard style={{ gridColumn: 'span 2' }}>
                        <p className='title'>Cargas</p>
                        <TableContainer>
                        <StyledTable>
                            <thead>
                                <tr>
                                <th>Tipo de Carga</th>
                                <th>Nombre</th>
                                <th>Valor (USD)</th>
                                <th>Dimensiones</th>
                                <th>Peso Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {RFQData.loads.map((load, index) => (
                                <tr key={index}>
                                    <td>{load.load_type.name}</td>
                                    <td>{load.name}</td>
                                    <td>{load.value} USD</td>
                                    <td>
                                    {load.long} {load.long_unit} x {load.width} {load.width_unit} x {load.height} {load.height_unit}
                                    </td>
                                    <td>
                                    {load.total_weight} {load.total_weight_unit}
                                    </td>
                                </tr>
                                ))}
                            </tbody>
                            </StyledTable>
                        </TableContainer>
                    </InfoCard>
                </DataContainer>
            }
        </Container>
    );
}