import { Dropdown, Avatar, Text, Grid, User } from "@nextui-org/react";
import { Link, useNavigate } from "react-router-dom";
import { logOutAction } from "../../store/sessionDuck";
import { connect, useSelector } from "react-redux";
import ImpersonateModal from "./impersonateModal";
import { useEffect, useState } from "react";
import { getRoles } from "../../services/operations.services";
import { stopImpersonate } from "../../services/user.services";
import useMediaQueries from "../../hooks/useMediaQueries";

function DropdownAvatar({ logOutAction, user }) {
  const navigate = useNavigate();
  const {sm, md, lg} = useMediaQueries();
  const token = useSelector((state) => state.user.token);
  const isImpersonating = localStorage.getItem("impersonating");
  const [openModal, setOpenModal] = useState(false);
  const [role, setRole] = useState([]);
  console.log("md :",md)
  console.log("sm :",sm)
  console.log("lg :",lg)
  useEffect(() => {
    if (user) {
      getRoles(token).then(async (res) => {
        const rolesData = await res.json();
        let userRole = rolesData.find((role) => role.id === user?.role_id);
        setRole(userRole);
      });
    }
  }, []);

  function handleStop() {
    stopImpersonate(token)
      .then((res) => {
        console.log("res :", res);
        localStorage.setItem("impersonating", false);
      })
      .catch((err) => console.log("err :", err));
  }

  function handlerAction(key) {
    switch (key) {
      case "profile":
        navigate("/profile/?tab=information");
        break;
      case "impersonate":
        setOpenModal(true);
        break;
      case "logout":
        logOutAction();
        break;
      case "stop_impersonating":
        handleStop();
        break;
    }
  }
  return (
    <>
      <Grid.Container justify="flex-start" gap={2}>
        <Grid>
          <Dropdown placement="bottom-left">
            <Dropdown.Trigger>
              <User
                bordered
                as="button"
                size="md"
                color="primary"
                
                name={!sm && `${user.first_name} ${user.last_name}`}
                description={
                  !sm && <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      fontSize: "10px",
                    }}
                  >
                    <span>{user?.organization?.name}</span>
                    <span>{user?.email}</span>
                  </div>
                }
                text={
                  user.first_name
                    ? `${user?.first_name[0]?.toUpperCase()}${user?.last_name[0]?.toUpperCase()}`
                    : ""
                }
                // src="https://i.pravatar.cc/150?u=a042581f4e29026024d"
              />
            </Dropdown.Trigger>
            <Dropdown.Menu
              color="primary"
              aria-label="User Actions"
              onAction={(key) => handlerAction(key)}
            >
              <Dropdown.Item key="profile" withDivider>
                <p style={{ color: "#292E68" }}>Ver perfil</p>
                {/* <Icon name="User"/> */}
              </Dropdown.Item>
              {role?.name == "Admin" && (
                <Dropdown.Item key="impersonate" withDivider>
                  <p style={{ color: "#292E68" }}>Actuar como usuario</p>
                </Dropdown.Item>
              )}
              {role?.name == "Admin" && isImpersonating && (
                <Dropdown.Item key="stop_impersonating" withDivider>
                  <p style={{ color: "#292E68" }}>
                    Dejar de actuar como usuario
                  </p>
                </Dropdown.Item>
              )}
              <Dropdown.Item key="logout" withDivider>
                <p style={{ color: "#292E68" }}>Cerrar sesión</p>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Grid>
      </Grid.Container>
      <ImpersonateModal open={openModal} setOpen={setOpenModal} />
    </>
  );
}

const mapState = ({}) => {
  return {};
};

export default connect(mapState, { logOutAction })(DropdownAvatar);
