import React, { useEffect, useState } from "react";
import { Input as Inp, Modal, styled } from "@nextui-org/react";
import theme from "../../../../theme";
import { useFormContext, Controller } from "react-hook-form";
import * as XLSX from "xlsx";
//Componentes
import PageTitle from "../../../../components/PageTitle/PageTitle";
import RadioGroup from "../../../../components/RadioGroup/RadioGroup";
import Input from "../../../../components/Input/Input";
import Dropdown from "../../../../components/Dropdown/Dropdown";
import Button from "../../../../components/Button/Button";
import Boat2 from "../../../../components/Graphics/Boat/Boat2";
import Icon from "../../../../components/Icon/Icon";
import { useTranslation } from "react-i18next";

const StyledModal = styled(Modal, {
  color: "$primary_500 !important",
  padding: "20px",
});

import {
  validateAllLoad,
  validateCurrent,
  calculateTotalTons,
  calculateCBM,
  calculateTotalPieces,
} from "./Functions";
import InputFileCard from "../../../../components/InputFileCard/InputFileCard";
import toast from "../../../../components/Toast/ToastTypes";
import useMediaQueries from "../../../../hooks/useMediaQueries";

const ExcelButton = styled("button", {
  display: "flex",
  background: "white",
  color: "$excel",
  gap: "5px",
  borderRadius: "8px",
  padding: "10px",
  transition: "300ms",
  border: "1px solid $excel",
  justifyContent: "center",
  ["&:hover"]: {
    cursor: "pointer",
    background: "$neutral_100",
  },
});

const StyledLoad = styled("div", {
  [`.${theme} &`]: {
    boxShadow:'$elevation_1',
    margin: "3rem 8rem 0rem 8rem",
    padding: "3rem 8rem",
    backgroundColor: "$neutral_50",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    ".header": {
      display: "flex",
      justifyContent: "space-between",
      color: "$primary_300",
      fontSize: "18px",
      div: { display: "flex", gap: "10px", alignItems: "center" },
    },
    ["aside"]: {
      color: "$primary_200",
    },
    ["main"]: {
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
    },
    [".separate"]: {
      margin: "0rem 1rem",
    },
    [".form"]: {
      display: "grid",
      gridTemplateColumns: "repeat(2, minmax(200px, 1fr))",
      gap: "0.5rem",
    },
    [".inputContainer"]: {
      [".nextui-input-main-container"]: {
        width: "100%",
        ["label"]: {
          color: "$primary_500",
        },
      },
      [".nextui-input-content--right"]: {
        width: "fit-content",
        padding: "0rem",
        [".nextui-dropdown-button"]: {
          height: "100%",
        },
      },
    },
    [".inputDropdown"]: {
      borderRadius: "8px",
      backgroundColor: "white",
      border: "solid 1px $primary_300",
      transition: "border 0.25s ease",
      marginRight: 0,
      "&:focus-within": {
        border: `solid 1px $primary_100`,
      },
      ".nextui-input-wrapper": {
        borderRadius: "8px",
        backgroundColor: "$neutral_50",
      },
      ".nextui-input-label--right": {
        borderRadius: "0px 8px 8px 0px",
        backgroundColor: "$neutral_100",
      },
    },
    [".cardUnit"]: {
      padding: "0rem 1.25rem",
      backgroundColor: "$neutral_100",
      height: "100%",
      display: "flex",
      alignItems: "center",
      borderRadius: "8px",
      color: "$primary_500",
      fontSize: "0.875rem",
    },
    [`.form-container`]: {
      padding: "10px",
      marginBottom: "10px",
    },

    [`.minimized-forms`]: {
      display: "flex",
      flexWrap: "wrap",
    },
    [`.minimized-form-container`]: {
      flexBasis: "calc(100% / 8)",
    },
    [".load-info"]: {
      display: "flex",
      justifyContent: "space-around",
      margin: "15px",
      fontSize:'13px'
    },
  },
  "@media (max-width: 1200px)":{
    [`.${theme} &`]:{
      margin:"1rem 2rem",
      padding:"2rem",
      fontSize:'12px',
    },
  },
  "@media (max-width: 900px)":{
    [`.${theme} &`]:{
      margin:"1rem",
      padding:"1rem",
    },
  },
  "@media (max-width: 550px)":{
    [`.${theme} &`]:{
      [".form"]: {
        display: "grid",
        gridTemplateColumns: "auto",
      },
      [".header"]: {
        flexDirection:'column',
        gap:'20px',
      },
      [".load-info"]: {
        flexDirection:'column',
        margin: "15px",
      },
    },
  },
});
const Paginado = styled("div", {
  display: "flex",
  gap: "10px",
});

// const containerSizes = [ //Metros
//     { value: {long:5.90, height:2.435, width:2.40, name: '20 pies'}, name: '20 pies'},
//     { value: {long:12.03, height:2.435, width:2.40, name: '40 pies'}, name: '40 pies' },
//     { value: {long:12.03, height:2.40, width:2.71, name: '40 HC pies'}, name: '40 HC pies'}
// ];

const containerSizes = [
  {
    value: "20",
    measurements: { long: 5.9, height: 2.435, width: 2.4 },
    name: "20 pies",
  },
  {
    value: "40",
    measurements: { long: 12.03, height: 2.435, width: 2.4 },
    name: "40 pies",
  },
  {
    value: "40HC",
    measurements: { long: 12.03, height: 2.4, width: 2.71 },
    name: "40 HC pies",
  },
];
const distanceUnits = [
  { key: "cm", name: "cm" },
  { key: "m", name: "m" },
  { key: "in", name: "in" },
];
const weightUnits = [
  { key: "kg", name: "kg" },
  { key: "lb", name: "lb" },
];
const insuranceOptions = [
  { value: true, name: "Sí, ya cuento." },
  { value: false, name: "No, aún no cuento." },
];

const nameForm = "load";

function Load({
  activeStep,
  countries,
  setDisabledContinue,
  load,
  setLoad,
  loadType,
  loadTypes,
}) {
  const { register, control, getValues, setValue, watch } = useFormContext();
  const shippingMethod = getValues("commerce.shippingMethod.value");
  const {sm, md} = useMediaQueries()
  const commerceType = getValues("commerce.commerceType");
  const { t: translate } = useTranslation("Quotation");

  const values = getValues()[nameForm];
  const [totalPieces, setTotalPieces] = useState(0);
  const [totalTons, setTotalTons] = useState(0);
  const [totalCBM, setTotalCBM] = useState(0);
  const [disableAddLoad, setDisableAddLoad] = useState(false);
  const [expandedForm, setExpandedForm] = useState(0);
  const [loadCount, setLoadCount] = useState(
    load?.load ? Object.keys(load?.load).length : 1
  );

  const [file, setFile] = useState();

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const handleChangeDropdown = (value, name, i) => {
    setValue(name, value[0]);
    if (name === `${nameForm}[${i}].unitWeightItem`) {
      setValue(`${nameForm}[${i}].totalWeightUnit`, value[0]);
    }

    if (
      name === `${nameForm}[${i}].longUnit` ||
      name === `${nameForm}[${i}].widthUnit` ||
      name === `${nameForm}[${i}].heightUnit`
    ) {
      setValue(`${nameForm}[${i}].longUnit`, value[0]);
      setValue(`${nameForm}[${i}].widthUnit`, value[0]);
      setValue(`${nameForm}[${i}].heightUnit`, value[0]);
    }
  };

  const formValues = watch();

  useEffect(() => {
    const values = getValues();
    setTotalTons(calculateTotalTons(values));
    setTotalPieces(calculateTotalPieces(values));
    setTotalCBM(calculateCBM(values));
    if (activeStep === 4) {
      if (validateAllLoad(values, shippingMethod)) {
        setDisabledContinue(false);
      } else {
        setDisabledContinue(true);
      }
    }
    // if (validateCurrent(getValues(), expandedForm, shippingMethod)) {
    //     setDisableAddLoad(false)
    // } else { setDisableAddLoad(true)}
    if (values.load[expandedForm].containerSize?.measurements) {
      const measurements = values.load[expandedForm].containerSize.measurements;
      const cbm = measurements.width * measurements.height * measurements.long;
      setTotalCBM(cbm);
    }
    if (
      values.load[expandedForm]?.amount &&
      values.load[expandedForm]?.unitWeight &&
      values.load[expandedForm].totalWeight !==
        values.load[expandedForm].amount * values.load[expandedForm].unitWeight
    ) {
      const totalW =
        values.load[expandedForm].amount * values.load[expandedForm].unitWeight;
      setValue(`${nameForm}[${expandedForm}].totalWeight`, totalW);
    }
  }, [formValues]);


  useEffect(() => {
    if (!getValues(`${nameForm}[${expandedForm}].insurance`)) {
      setValue(`${nameForm}[${expandedForm}].insurance`, false);
    }
  }, [expandedForm]);

  const handleAddLoad = () => {
    const values = getValues();
    const isValid = validateCurrent(values, expandedForm, shippingMethod)
    if (!isValid?.ok){
      toast("warn",isValid.message)
    } else {
      setLoad(values);
      setLoadCount(loadCount + 1);
      setExpandedForm(loadCount);
      if (shippingMethod === 2) {
        setValue(`${nameForm}[${expandedForm + 1}].contenedorCompleto`, true);
      }
      setValue(`${nameForm}[${expandedForm + 1}].longUnit`, "cm");
      setValue(`${nameForm}[${expandedForm + 1}].widthUnit`, "cm");
      setValue(`${nameForm}[${expandedForm + 1}].heightUnit`, "cm");
      setValue(`load.${expandedForm + 1}.unitWeightItem`, "kg");
      setValue(`load[${expandedForm + 1}].totalWeightUnit`, "kg");
    }
  };

  const handleEditLoad = (index) => {
    setExpandedForm(index);
  };
  const handleDeleteLoad = () => {
    if (loadCount > 1) {
      const values = watch();
      const newLoadData = values.load;
      delete newLoadData[expandedForm];
      const sortedLoadData = {};
      const loadArray = Object.keys(newLoadData).map((key, i) => {
        return newLoadData[key];
      });
      loadArray.forEach((load, i) => {
        sortedLoadData[i] = load;
      });
      setValue("load", sortedLoadData);
      setOpenConfirmationModal(false);
      setLoadCount(loadCount - 1);
    }
    if (expandedForm === loadCount - 1) {
      setExpandedForm(expandedForm - 1);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };
  const handleFileUpload = () => {
    if (!file) {
      toast("warn", "No se selecciono ningun archivo");
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      const range = XLSX.utils.decode_range(sheet["!ref"]);
      const newRange = { s: { c: 0, r: 0 }, e: { c: 10, r: range.e.r } };
      sheet["!ref"] = XLSX.utils.encode_range(newRange);
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      const loadsArray = jsonData.slice(1);

      const formattedData = {};
      loadsArray.forEach((row, index) => {
        if (row.length) {
          let [
            loadName,
            amount,
            loadValue,
            container,
            long,
            width,
            height,
            measurementUnit,
            weightPerPiece,
            totalWeight,
            weightUnit,
            insurance,
          ] = row;

          if (shippingMethod !== 2) {
            [
              loadName,
              amount,
              loadValue,
              long,
              width,
              height,
              measurementUnit,
              weightPerPiece,
              totalWeight,
              weightUnit,
              insurance,
            ] = row;
            container = null;
          }

          let containerData = null;
          let contenedorCompleto = false;
          if (container) {
            containerData = containerSizes.find(
              (item) =>
                item.value.toLowerCase() == container.toString().toLowerCase()
            );
            contenedorCompleto = true;
            long = containerData.measurements.long;
            height = containerData.measurements.height;
            width = containerData.measurements.width;
          }

          formattedData[index] = {
            insurance: insurance == "Si" || insurance == "si",
            longUnit: measurementUnit.toLowerCase(),
            widthUnit: measurementUnit.toLowerCase(),
            heightUnit: measurementUnit.toLowerCase(),
            totalWeightUnit: weightUnit.toLowerCase(),
            unitWeightItem: weightUnit.toLowerCase(),
            contenedorCompleto,
            containerSize: containerData || "",
            long,
            width,
            height,
            unitWeight: weightPerPiece,
            totalWeight: parseFloat(totalWeight),
            loadName,
            loadValue: loadValue.toString(),
            amount,
          };
          if (!formattedData[index]?.containerSize) {
            delete formattedData[index]?.containerSize;
          }
        }
        setLoadCount(Object.keys(formattedData).length);
        setValue("load", formattedData);
      });
    };
    reader.readAsArrayBuffer(file);
  };

  return (
    <StyledLoad>
      <div className="header">
        <PageTitle>{translate("load-information")}</PageTitle>
        <div>
          <span>
            {
              countries.find(
                (country) => country.id === getValues("send.originCountry")
              )?.name
            }
          </span>
          <Icon name="SimpleArrowRight" size="30px" color="#3D509A" />
          <span>
            {
              countries.find(
                (country) => country.id === getValues("send.destinationCountry")
              )?.name
            }
          </span>
          <Icon
            size="35px"
            name={
              getValues("commerce.shippingMethod.value") === 1
                ? "Aereo"
                : [2, 4, 5, 6].includes(
                    getValues("commerce.shippingMethod.value")
                  )
                ? "Maritimo"
                : "Terrestre"
            }
          />
        </div>
      </div>
      <InputFileCard
        label="Sube un archivo .xls o .xlsx"
        name="loads xls"
        startIcon={{ name: "Upload", color: "#FFF" }}
        width={"100%"}
        aspectRatio={"6/1"}
        onChange={(e) => handleFileChange(e)}
        accept=".xls,.xlsx"
      />
      <ExcelButton
        onClick={() => {
          handleFileUpload();
        }}
      >
        Subir excel de cargas <Icon name="Excel" size="26" />
      </ExcelButton>
      {shippingMethod === 2 ? (
        <a href="https://docs.google.com/spreadsheets/d/e/2PACX-1vROMWKAcd6HMj66dfWJEpNNtBXc7pmhlIZcZ3AMHMWzutnlXIaJVgpJIazmkOgOLA/pub?output=xlsx">
          Plantilla de excel de cargas maritimas
        </a>
      ) : (
        <a href="https://docs.google.com/spreadsheets/d/e/2PACX-1vS5JTktA7xEDPL4HFGj-3H01AErVMUXEPzAjsTFmECao76nCSbIudYFPTrc6qoskQ/pub?output=xlsx">
          Plantilla de excel de cargas
        </a>
      )}
      {loadType === loadTypes[0]?.id ? (
        <aside>{translate("shipment-information-general")}</aside>
      ) : (
        loadType === loadTypes[1]?.id && (
          <aside>{translate("shipment-information-project")}</aside>
        )
      )}
      <div className="form-wrapper">
        <div className="expanded-form">
          {Array.from({ length: loadCount }, (_, i) =>
            expandedForm === i ? (
              <main key={i}>
                <div className="form-container">
                  <>
                    {shippingMethod === 2 && (
                      <>
                        <Controller
                          control={control}
                          name={`${nameForm}[${i}].contenedorCompleto`}
                          defaultValue={true}
                          render={({ field: { onChange, value } }) => (
                            <RadioGroup
                              orientation="horizontal"
                              onChange={(value) => {
                                onChange(value);
                              }}
                              value={value}
                              label="Contenedor completo?"
                              options={[
                                { name: "Si", value: true },
                                { name: "No", value: false },
                              ]}
                              required
                            />
                          )}
                        ></Controller>
                        {watch(`${nameForm}[${i}].contenedorCompleto`) && (
                          <div style={{ margin: "20px 5px" }}>
                            <Controller
                              control={control}
                              name={`${nameForm}[${i}].containerSize`}
                              render={({ field: { onChange, value } }) => {
                                return (
                                  <RadioGroup
                                    onChange={(value) => {
                                      onChange(
                                        containerSizes.find(
                                          (e) => e.value === value
                                        )
                                      );
                                    }}
                                    name={`${nameForm}[${i}].containerSize`}
                                    value={watch(
                                      `${nameForm}[${i}].containerSize.value`
                                    )}
                                    label="Selecciona un contenedor"
                                    options={containerSizes}
                                    required
                                  />
                                );
                              }}
                            ></Controller>
                          </div>
                        )}
                      </>
                    )}
                    <div className="form">
                      <Controller
                        control={control}
                        name={`${nameForm}[${i}].loadName`}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Input
                            {...field}
                            size={md?"md":'sm'}
                            value={values[i]?.loadName}
                            label="Nombre de la carga"
                            placeholder="Introduce nombre"
                            required
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name={`${nameForm}[${i}].loadValue`}
                        rules={{
                          required: true,
                        }}
                        render={({ field }) => (
                          <Input
                            {...field}
                            size={md?"md":'sm'}
                            value={values[i]?.loadValue}
                            label="Valor de la carga USD"
                            placeholder="$ Introducir valor"
                            type="number"
                            required
                          />
                        )}
                      />
                      {(shippingMethod !== 2 ||
                        !watch(`${nameForm}[${i}].contenedorCompleto`)) && (
                        <div className="inputContainer">
                          <Inp
                            {...register(`${nameForm}[${i}].long`)}
                            className="inputDropdown"
                            label="Largo"
                            size={md?"md":'sm'}
                            placeholder="Introducir"
                            type={"number"}
                            animated={false}
                            contentRight={
                              <Dropdown
                                onChange={(value) =>
                                  handleChangeDropdown(
                                    value,
                                    `${nameForm}[${i}].longUnit`,
                                    i
                                  )
                                }
                                name={`${nameForm}[${i}].longUnit`}
                                options={distanceUnits}
                                value={watch(`load.${expandedForm}.longUnit`)}
                                bgColor="neutral_100"
                              />
                            }
                          />
                        </div>
                      )}
                      {(shippingMethod !== 2 ||
                        !watch(`${nameForm}[${i}].contenedorCompleto`)) && (
                        <div className="inputContainer">
                          <Inp
                            {...register(`${nameForm}[${i}].width`)}
                            className="inputDropdown"
                            label="Ancho"
                            size={md?"md":'sm'}
                            placeholder="Introducir"
                            type={"number"}
                            animated={false}
                            contentRight={
                              <Dropdown
                                onChange={(value) =>
                                  handleChangeDropdown(
                                    value,
                                    `${nameForm}[${i}].widthUnit`,
                                    i
                                  )
                                }
                                name={`${nameForm}[${i}].widthUnit`}
                                options={distanceUnits}
                                value={watch(`load.${expandedForm}.widthUnit`)}
                                bgColor="neutral_100"
                              />
                            }
                          />
                        </div>
                      )}
                      {(shippingMethod !== 2 ||
                        !watch(`${nameForm}[${i}].contenedorCompleto`)) && (
                        <div className="inputContainer">
                          <Inp
                            {...register(`${nameForm}[${i}].height`)}
                            className="inputDropdown"
                            label="Alto"
                            size={md?"md":'sm'}
                            placeholder="Introducir"
                            type={"number"}
                            animated={false}
                            contentRight={
                              <Dropdown
                                onChange={(value) =>
                                  handleChangeDropdown(
                                    value,
                                    `${nameForm}[${i}].heightUnit`,
                                    i
                                  )
                                }
                                name={`${nameForm}[${i}].heightUnit`}
                                options={distanceUnits}
                                value={watch(`load.${expandedForm}.heightUnit`)}
                                bgColor="neutral_100"
                              />
                            }
                          />
                        </div>
                      )}
                      <div className="inputContainer">
                        <Controller
                          control={control}
                          name={`${nameForm}[${i}].amount`}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <Input
                              {...field}
                              size={md?"md":'sm'}
                              value={values[i]?.amount}
                              label="Cantidad"
                              placeholder="$ Introducir cantidad"
                              type="number"
                              required
                            />
                          )}
                        />
                      </div>
                      <div className="inputContainer">
                        <Inp
                          size={md?"md":'sm'}
                          {...register(`${nameForm}[${i}].unitWeight`)}
                          className="inputDropdown"
                          label="Peso por pieza"
                          placeholder="Introducir"
                          type={"number"}
                          animated={false}
                          contentRight={
                            <Dropdown
                              onChange={(value) =>
                                handleChangeDropdown(
                                  value,
                                  `${nameForm}[${i}].unitWeightItem`,
                                  i
                                )
                              }
                              name={`${nameForm}[${i}].unitWeightItem`}
                              options={weightUnits}
                              bgColor="neutral_100"
                            />
                          }
                        />
                      </div>
                      <div className="inputContainer">
                        <Inp
                          {...register(`${nameForm}[${i}].totalWeight`)}
                          size={md?"md":'sm'}
                          className="inputDropdown"
                          label="Peso total"
                          placeholder="Introducir"
                          type={"number"}
                          animated={false}
                          /*  disabled
                          css={{ 'input:disabled': { color: 'inherit' } }} */
                          contentRight={
                            <div className="cardUnit">
                              {watch(`${nameForm}[${i}].totalWeightUnit`)}
                            </div>
                          }
                        />
                      </div>
                    </div>
                    <hr className="separate" />
                    <Controller
                      control={control}
                      className="w-50"
                      name={`${nameForm}[${i}].insurance`}
                      render={({ field }) => (
                        <RadioGroup
                          name={`${nameForm}.insurance`}
                          {...field} // Esto garantiza que el valor del campo sea el valor actual en el estado del formulario
                          label="¿Ya cuentas con un seguro?"
                          options={insuranceOptions}
                          required
                        />
                      )}
                    />
                  </>
                </div>
              </main>
            ) : null
          )}
        </div>
        <Button
          handler={handleAddLoad}
          text={"Añadir otra carga"}
          size={md?"md":'sm'}
          bold
          outline
          width="100%"
          disabled={disableAddLoad}
        />
        <div style={{ marginTop: "30px" }}>
          <Button
            text={"Eliminar carga"}
            size={md?"md":'sm'}
            bold
            outline
            width="100%"
            disabled={loadCount < 2}
            textColor={"delete"}
            handler={() => {
              setOpenConfirmationModal(true);
            }}
          />
        </div>
      </div>
      <div className="load-info">
        <span>Peso: {totalTons?.toFixed(2)} toneladas</span>
        <span>Cargas : {loadCount}</span>
        <span>Piezas : {totalPieces}</span>
        <span>cbm : {totalCBM.toFixed(2)} m³</span>
      </div>
      {loadCount > 1 ? (
        <Paginado>
          {Array.from({ length: loadCount }, (_, i) => (
            <Button
              text={values[i]?.loadName || "no name"}
              outline
              bold={expandedForm === i}
              handler={() => handleEditLoad(i)}
            ></Button>
          ))}
        </Paginado>
      ) : null}
      {shippingMethod === 2 ? (
        // <Boat loadCount={loadCount} expandedForm={expandedForm} handleEditLoad={handleEditLoad} handleDeleteLoad={handleDeleteLoad} /> :
        <Boat2 loadCount={loadCount} />
      ) : null}

      <StyledModal
        open={openConfirmationModal}
        onClose={() => setOpenConfirmationModal(false)}
      >
        <h3>Seguro que desea eliminar esta carga?</h3>
        <Modal.Footer>
          <Button
            text={"Cancelar"}
            outline
            handler={() => setOpenConfirmationModal(false)}
          />
          <Button
            text={"Confirmar"}
            outline
            textColor={"delete"}
            handler={() => handleDeleteLoad(expandedForm)}
          />
        </Modal.Footer>
      </StyledModal>
    </StyledLoad>
  );
}
export default Load;
