import React, { useEffect, useState } from 'react';
import { styled } from '@nextui-org/react';
import theme from '../../theme';
import { useSearchParams } from 'react-router-dom';
import toast from '../../components/Toast/ToastTypes';
import { debounce } from 'lodash';
// Components
import PageTitle from '../../components/PageTitle/PageTitle';
import Input from '../../components/Input/Input';
import Dropdown from '../../components/Dropdown/Dropdown';
import Button from '../../components/Button/Button';
import TableServices from './TableServices/TableServices';
import src from '../../assets/img/serviceTable.png';
import { useTranslation } from "react-i18next";
import OnBoarding from '../../components/onBoarding/onBoarding';
import Cloropeth from '../../components/Cloropeth/Cloropeth';
import { Tooltip } from "react-tooltip";
import CloropethContainer from '../../components/Cloropeth/Container';
import useMediaQueries from '../../hooks/useMediaQueries';

const StyledServices = styled('article', {
    [`.${theme} &`]: {
        padding: '3rem 2rem',
        display: "flex",
        height: "100%",
        flexDirection: "column",
        gap: '1.5rem',
        ['.header']: {
            display: 'flex',
            gap: '1.5rem'
        },
    }
})

const onBoardingSteps = [
    {
      target: '.servicesPageTitle',
      disableScrolling:true,
      content: 
      <div>
        <h3>Servicios</h3>
        <p>Bienvenido al menu de cotizaciones, aqui puedes visualizar, administrar y crear cotizaciones</p>
      </div>,
    },
    {
      target: '.servicesTable',
      content: 
        <div style={{textAlign:'left'}}>
          <h3>Ver servicios</h3>
          <p>En esta tabla se muestran los servicios. Los servicios son operaciones en proceso que nacen a partir de las cotizaciones.</p>
        </div>,
      disableScrolling:true,
    },
    {
        target: '.servicesTable',
        content: 
          <div style={{textAlign:'left'}}>
            <h3>Ver detalles</h3>
            <p style={{fontSize:'13px'}}>Asegurate de revisar la pestaña de detalles del servicio, donde podras acceder al chat, los registros de la operacion y mucha mas informacion.</p>
            <p style={{fontSize:'13px'}}>Accede mediante el menu desplegable :</p>
            <img src={src} alt="" />
          </div>,
        disableScrolling:true,
      },
  ];

const Services = () => {
    const {sm} = useMediaQueries()
    const [searchParams] = useSearchParams()
    const [filterType, setFilterType] = useState("Seleccionar");
    const [filterSearch, setFilterSearch] = useState('')
    const [loading, setLoading] = useState(true);

    const { t: translate } = useTranslation("Services");

    const [options, setoptions] = useState([
        { key: "Seleccionar", name: "Seleccionar" },
        { key: "Carga general", name: "Carga general" },
        { key: "Carga proyecto", name: "Carga proyecto" }
    ]);

    useEffect(() => {
        setTimeout(() => {
            let message;
            if (searchParams.get('created') === 'liquidation') {
                message = '¡Se ha aprobado con éxito tu liquidación! '
                toast('success', message)
            } else if (searchParams.get('created') === 'pre-liquidation') {
                const title = 'Tu pre-liquidación ha sido aprobada con éxito'
                message = <>Recuerda que en las próximas <b>24 horas</b> recibiras tu liquidación final.</>
                toast('success', message, title)
            }
        }, 1000)
    }, []);

    const handleChange = (value) => {
        setFilterSearch(value)
    }
    const debouncedSearch = debounce((value) => {
        handleChange(value)
    }, 300);

    return (
        <StyledServices>
            <OnBoarding steps={onBoardingSteps}/>
            <div className='servicesPageTitle'>
                <PageTitle>
                    {translate("title")}
                </PageTitle>
            </div>
            <div className="header">
                <Input 
                    placeholder={translate("search")}
                    startIcon={{ name: 'Search' }}
                    onChange={(e) => { debouncedSearch(e.target.value) }}
                    disabled={loading}
                    fullWidth
                />
                {!sm && <Dropdown options={options} onChange={data => setFilterType(data[0])} outline></Dropdown>}
                {!sm && <Button text={translate("download")} endIcon={{ name: 'Download' }} outline bold></Button>}
            </div>
           {/*  <CloropethContainer title={'Mapa'} icon="Maritimo"></CloropethContainer> */}
        
            <div className="servicesTable">
                <TableServices filterType={filterType} filterSearch={filterSearch} loading={loading} setLoading={setLoading}/>
            </div>
        </StyledServices> 
    );
}

export default Services;
