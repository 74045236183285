import React, { useState, useEffect } from "react";
import { useFetch } from "../../../hooks/useFetch";
import { URL, bgColorStates, textColorStates } from "../../../constants";
import { useSelector } from "react-redux";
import { Loading, Modal, styled } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import DataModal from "../../../components/Modal/DataModal/DataModal";
import ItemModal from "../../../components/Modal/ItemModal/ItemModal";
import {getPurchaseOrders} from "../../../services/quotation.services";
import { getServiceSplit } from "../../../services/quotation.services";
import { getOperations, getSubStatuses, getSubStatusesOperations} from "../../../services/operations.services";
// Components
import Table from "../../../components/Table/Table";
import Button from "../../../components/Button/Button";
import toast from "../../../components/Toast/ToastTypes";
import Pagination from "../../../components/Pagination/Pagination";
import { getOffers } from "../../../services/quotations.services";
import { getPreliquidations } from "../../../services/preliquidation.services";
import { getPreliquidationCosts } from "../../functions";
//
import { useTranslation } from "react-i18next";

const TableServices = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.token);
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Token " + token,
  };
  const { t: translate } = useTranslation("Admin");

  const [modalStyle, setModalStyle] = useState({ display: "none" });
  const [modal, setModal] = useState(null);
  // const { data, loading } = useFetch(`${URL}service/all/`, headers);
  const roles = useFetch(`${URL}roles/`, headers);
  const states = useFetch(`${URL}states/`, headers);
  const asesores = useFetch(`${URL}user/rol/5/`, headers);
  const [advisor, setAdvisor] = useState();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const [currentService, setCurrentService] = useState();
  const [loading, setLoading] = useState(false);
  //Paginado
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const style = {
    display: "block",
    backgroundColor: "rgba(0, 0, 0, .7)",
    position: "absolute",
    top: "0",
    left: "0",
    height: "100%",
    width: "100%",
    zIndex: "300",
  };

  const StyledModal = styled(Modal, {
    color: "$primary_500 !important",
    minWidth: "400px",
    padding: "20px",
  });

  const columns = [
    { name:translate("Actions"), uid: "actions", type: "action" },
    { name:translate("radication_id"), uid: "radication", type: "text" },
    { name:translate("load"), uid: "load", type: "text" },
    { name:translate("date"), uid: "date", type: "date" },
    { name:translate("hour"), uid: "hour", type: "hour" },
    { name:translate("origin"), uid: "origin", type: "text" },
    { name:translate("destination"), uid: "destination", type: "text" },
    { name:translate("advisor"), uid: "advisor", type: "text" },
    { name:translate("po"), uid: "purchaseOrder", type: "text" },
    { name:translate("cost"), uid: "costs", type: "text" },
  ];

  const handleDeleteService = () => {
    //     deleteService(token, currentService.radication).then(()=>{
    //         toast("success", "El servicio a sido eliminado")
    //     }).catch(() => {
    //         toast('error','No se ha podido eliminar el servicio')
    //     })
    toast("warn", "Aun no esta disponible");
  };

  const closeModal = () => {
    setModal(null);
    setModalStyle({ display: "none" });
  };

  const advisorModal = (userData) => {
    let advisors = userData.advisor.trim();
    if (advisors != "") {
      const advisorsList = advisors.split(",");
      const containAdvisor = asesores.data.filter(
        (advisor) => !advisorsList.includes(advisor.first_name)
      );
      advisors = containAdvisor?.length === 0 ? null : containAdvisor;
    } else advisors = asesores.data;

    setModalStyle(style);
    setModal(
      <DataModal
        data={advisors}
        titleText="Seleccione el nuevo asesor"
        buttonText="Asignar"
        close={closeModal}
        id={userData.id}
        endpoint="services/add-advisor/"
        methodType="POST"
        headers={headers}
      />
    );
  };
  const stateModal = (serviceData) => {
    const serviceState = states.data.filter((state) => {
      return (
        (serviceData.state.name == "Aprobado" &&
          state.name != "Cotizado" &&
          state.name != "Aprobado") ||
        (serviceData.state.name != "Aprobado" &&
          state.name != serviceData.state.name)
      );
    });

    setModalStyle(style);
    setModal(
      <DataModal
        data={serviceState}
        titleText="Seleccione el nuevo estado"
        buttonText="Cambiar"
        close={closeModal}
        id={serviceData.id}
        endpoint="services/changeState/"
        methodType="PUT"
        headers={headers}
      />
    );
  };
  const deleteAdvisorModal = (serviceData) => {
    setModalStyle(style);
    setModal(
      <DataModal
        data={
          advisor[serviceData.id]?.length != 0 ? advisor[serviceData.id] : null
        }
        titleText="Eliminar asesor"
        buttonText="Eliminar"
        contentText="¿Esta seguro que desea eliminar el asesor?"
        close={closeModal}
        id={serviceData.id}
        endpoint="services/delete-advisor/"
        methodType="DELETE"
        headers={headers}
      />
    );
  };

  const costModal = (serviceData) => {
    setModalStyle(style);
    setModal(
      <ItemModal
        titleText="Asignar costos"
        buttonText="Asignar"
        close={closeModal}
        id={serviceData.id}
        endpoint="service/assign-costs/"
        methodType="POST"
        headers={headers}
      />
    );
  };

  const actions = [
    {
      name: "Ver Servicio",
      icon: "View",
      handle: (service) => {
        navigate(
          `/services/detail/chat/?radication=${service.idRadication}&tab=chat`
        );
      },
    },
    {
      name: "Cambiar estado",
      icon: "Edit",
      type: "button",
      handle: stateModal,
    },
    {
      name: "Asignar Asesor",
      icon: "UserAdd",
      type: "button",
      handle: advisorModal,
    },
    {
      name: "Eliminar Asesor",
      icon: "Trash",
      type: "button",
      handle: deleteAdvisorModal,
    },
    {
      name: "Asignar costos",
      icon: "MoneyBag",
      type: "button",
      handle: costModal,
    },
    {
      name: "Eliminar servicio",
      icon: "Trash",
      type: "button",
      handle: (service) => {
        setCurrentService(service), setOpenConfirmationModal(true);
      },
    },
  ];

  function getData(currentPage, pageSize, filterSearch){
    const asyncTasks = [getServiceSplit(token, currentPage, pageSize, filterSearch),getPreliquidations(token),getPurchaseOrders(token)];
    const asyncData = Promise.all(asyncTasks).then(async (res) => {
      const servicesData = await res[0].json();
      const preliquidationsData = await res[1].json();
      const purchaseOrdersData = await res[2].json();
      
      const filteredServices = servicesData?.results;
      
      const asyncTableData = filteredServices.map(async (service) => {
        const offers = await getOffers(token, service.radication);
    
        const offerPrices = offers.map((offer) => {
          const lastPreliquidationId =  offer.preliquidation_ids[offer.preliquidation_ids?.length - 1];
          const lastPreliquidation = preliquidationsData.find((prel) => prel.id === lastPreliquidationId);
          const { totalCost } = getPreliquidationCosts(lastPreliquidation);
          return "$ " + totalCost?.toFixed(2);
        });
        
        const costs =  offerPrices.length > 0 ? offerPrices.join("/") : "No asignado";
        
        const asesor = service.users.find((user) => user.user?.groups?.includes("Asesores"))?.user ||
          service.users.find((user) => user.user?.groups?.includes("Pricing")) ?.user ||
          service.users.find((user) => user.user?.groups?.includes("Operations"))?.user;

        const purchaseOrder = purchaseOrdersData.find((po) => po.service === service.id)?.purchaseNumber || "No tiene";
        return {
          ...service,
          load: service.loads.length > 0? service?.loads[0]?.load_type?.name : "",
          date: new Date(service.created_at),
          origin: service.shipping.origin_boarding_terminal.city.country.name,
          destination: service.shipping.destination_boarding_terminal.city.country.name,
          advisor: `${asesor?.first_name || ""} ${asesor?.last_name || ""}`,
          purchaseOrder,
          costs,
        };
      });
      const tableData = await Promise.all(asyncTableData);
      setLoading(false);
      return {data:tableData, count:servicesData?.count}
    }).catch(() => setLoading(false));
    return asyncData;
  }

  return (
    <div className="h-100">
      {
        loading ? (
          <div className="d-flex justify-content-center">
            <Loading></Loading>
          </div>
        ) : <Table columns={columns} actions={actions} searchBar={false} serverPagination getData={getData}/>
      }
      <div style={modalStyle}>{modal}</div>
      <StyledModal
        aria-label="modal-title"
        open={openConfirmationModal}
        onClose={() => setOpenConfirmationModal(false)}
        width="fit-content"
      >
        <Modal.Header justify="space-between">
          <h3>Confirmar</h3>
        </Modal.Header>
        <Modal.Body justify="space-between">
          <p>Confirma que desea borrar el servicio seleccionado</p>
          <div style={{ display: "flex", justifyContent: "end", gap: "15px" }}>
            <Button
              text="Cancelar"
              bold
              bgColor={"primary_300"}
              textColor="neutral_50"
              handler={() => setOpenConfirmationModal(false)}
            />
            <Button
              text="Borrar"
              bold
              bgColor={"delete"}
              textColor="neutral_50"
              handler={() => handleDeleteService()}
            />
          </div>
        </Modal.Body>
      </StyledModal>
    </div>
  );
};

export default TableServices;
